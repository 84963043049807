import React, { useState, useEffect } from "react";

// STORE
import { useStore } from "../../store/stores/store";

// Components
import Pageflip from "./Pageflip.component";
import Documento from "./Documento.component";
import Gallery from "./Gallery.component";
import AudioPlayer from "./AudioPlayer/AudioPlayer.component";
import VideoPlayer from "../VideoPlayer.component";

const Resource = ({ risorsa, ...props }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const activeLang = useStore((x) => x.activeLang.value);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (risorsa.tipologia) {

    switch (risorsa.tipologia) {
      case "fumetto":
        
        if (risorsa.fumetto && risorsa.fumetto.length > 0) {
          return <Pageflip pages={risorsa.fumetto} mobile={isMobile} risorsa={risorsa} />;
        }else{
          return <span>Risorsa non disponibile</span>
        }

      case "documento":
        return <Documento risorsa={risorsa} />;

      case "galleria":

      if (risorsa.galleria && risorsa.galleria.length > 0) {
        return <Gallery images={risorsa.galleria} mobile={isMobile} risorsa={risorsa} />;
      }else{
        return <span>Risorsa non disponibile</span>
      }

      case "audio":
        return <AudioPlayer audio={risorsa} />;

        case "video":
          return <VideoPlayer url={risorsa.url} title={risorsa.titoloIt} />;  
  
      default:
        return <span>Risorsa non disponibile</span>;
    }
  }
};

export default Resource;

export const PARTNERSPAGE_ENG = {
    title: "Partners",
    description:
    "This section presents the partners and entities associated with the Oltrevita project. Click on the logos to access the official websites.",    breadcrumbs: [
      { pageName: "Home", path: "/", active: false },
      { pageName: "The Partners", path: "/Partners", active: true },
    ],
    joinUsTitle: "Join us",
    joinUs1: "Preserving history and enhancing ancient works of art is an important task that deserves to be shared with the world. If you represent an institution or a museum dedicated to the promotion of these precious treasures, we invite you to take part in the Oltrevita project, a unique opportunity to make your business known to a vast public passionate about art and culture.",
    joinUs2: "Join us and make a significant contribution to the conservation of the artistic heritage of our territory. To become part of the project, just fill out the contact form and leave your information. A member of our team will get back to you as soon as possible to provide you with more information about the project and to discuss how we can work together towards our common goal.",
    nameField: "Name",
    nameFieldPlaceholder: "Insert here your name",
    contactField: "Message",
    contactFieldPlaceholder: "Insert here your email or phone number",
    descField: "Description",
    descFieldPlaceholder: "Describe here your organization/association and how we could work together",
    resetButton: "Reset",
    sendButton: "Send"
  };
  
import { useState, useEffect, useRef, useCallback } from "react";

// ICONS
import {
  faPlay,
  faPause,
  faSquare,
  faVolumeOff,
  faVolumeHigh,
  faVolumeXmark,
  faRotateRight,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Controls = ({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  tracks,
  trackIndex,
  setTrackIndex,
  setCurrentTrack,
  handleNext,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      "--range-progress",
      `${(progressBarRef.current.value / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  const stopPlay = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const skipForward = () => {
    audioRef.current.currentTime += 15;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const buttonCss =
    "flex items-center justify-center w-[35px] h-[35px] bg-secondary hover:bg-secondary-dark rounded-full";

  return (
    <div className="w-full flex justify-center">
      <div className="flex gap-3">
        <button className={buttonCss} onClick={skipBackward}>
          <FontAwesomeIcon
            icon={faRotateLeft}
            color="#FFF"
            className="cursor-pointer"
            size="sm"
          />
        </button>

        <button className={buttonCss} onClick={togglePlayPause}>
          {isPlaying ? (
            <button>
              <FontAwesomeIcon
                icon={faPause}
                color="#FFF"
                className="cursor-pointer"
                size="sm"
              />
            </button>
          ) : (
            <FontAwesomeIcon
              icon={faPlay}
              color="#FFF"
              className="cursor-pointer"
              size="sm"
            />
          )}
        </button>

        <button className={buttonCss} onClick={stopPlay}>
          <FontAwesomeIcon
            icon={faSquare}
            color="#FFF"
            className="cursor-pointer"
            size="sm"
          />
        </button>

        <button className={buttonCss} onClick={skipForward}>
          <FontAwesomeIcon
            icon={faRotateRight}
            color="#FFF"
            className="cursor-pointer"
            size="sm"
          />
        </button>
      </div>
    </div>
  );
};

export default Controls;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, HashRouter } from "react-router-dom";

// STORE
import { useStore } from "./store/stores/store";

//PAGES
import Homepage from "./pages/Homepage.page";
import CollectionsPage from "./pages/reperti/Collections.page";
import Project from "./pages/Project.page";
import MuseumsPage from "./pages/Musei.page";
import UnitsPage from "./pages/reperti/Units.page";

//COMPONENTS
import Navbar from "./components/Navbar/Navbar.component";
import Footer from "./components/Footer.component";
import PartnersPage from "./pages/Partners.page";
import ScrollToTop from "./components/ScrollToTop.component";
import TestPage from "./pages/Test.page";
import OltrevitaObject from "./pages/OltrevitaObject.page";
import OltrevitaCollection from "./pages/OltrevitaCollection.page";

const App = () => {
  const setDictionary = useStore((x) => x.setDictionary);


  useEffect(() => {
    setDictionary();
  }, []);

  return (
    <div className="bg-primary">
      <HashRouter>
      <ScrollToTop />
        <Navbar />
        <div>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="*" element={<Homepage />} />

            <Route exact path="/Progetto" element={<Project />} />
            <Route exact path="/Musei" element={<MuseumsPage />} />

            <Route exact path="/Reperti/Collezioni" element={<CollectionsPage />} />
            <Route exact path="/Reperti/Opere" element={<UnitsPage />} />

            <Route exact path="/Partners" element={<PartnersPage />} />

            <Route path="collezioni/:id" />
            <Route path="collezioni/:id/:id" />

            {/* Oltrevita pages: collection / object */}
            <Route path="Oltrevita/Collezione/:id" element={<OltrevitaCollection />} />
            <Route path="Oltrevita/Opera/:id" element={<OltrevitaObject />} />

            <Route path="/Test" element={<TestPage />} />

          </Routes>
        </div>

        <Footer />
      </HashRouter>
    </div>
  );
};

export default App;

export const PARTNERSPAGE_ITA = {
  title: "Partners",
  description:
    "In questa sezione vengono presentati i partners e gli enti associati al progetto Oltrevita. Clicca sui loghi per accedere ai siti web ufficiali.",
  breadcrumbs: [
    { pageName: "Home", path: "/", active: false },
    { pageName: "I Partners", path: "/Partners", active: true },
  ],
  joinUsTitle: "Unisciti a noi",
  joinUs1:
    "Preservare la storia e valorizzare le opere d'arte antiche è un compito importante che merita di essere condiviso con il mondo. Se rappresenti un ente o un museo che si dedica alla promozione di questi preziosi tesori, ti invitiamo a prendere parte al progetto Oltrevita, un'opportunità unica per far conoscere la tua attività a un pubblico vasto e appassionato di arte e cultura.",
  joinUs2:
    "Unisciti a noi e dai un contributo significativo alla conservazione del patrimonio artistico del nostro territorio. Per diventare parte del progetto, basta compilare il form di contatto e lasciare le tue informazioni. Un membro del nostro team ti ricontatterà al più presto per fornirti ulteriori informazioni sul progetto e discutere su come possiamo lavorare insieme per raggiungere il nostro obiettivo comune.",
  nameField: "Nome",
  nameFieldPlaceholder: "Inserisci qui il tuo nome",
  contactField: "Contatto",
  contactFieldPlaceholder: "Inserisci qui la tua email o numero di telefono",
  descField: "Messaggio",
  descFieldPlaceholder:
    "Descrivi in questo spazio il tuo ente o associazione e come potremmo lavorare assieme",
    resetButton: "Pulisci",
    sendButton: "Invia"
};

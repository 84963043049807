import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// STORE
import { useStore } from "../store/stores/store";

// COMPONENTS
import Breadcrumb from "../components/Breadcrumb.component";
import PageLoading from "../components/PageLoading.component";
import Resource from "../components/Resource/Resource.component";

const OltrevitaCollection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const activeLang = useStore((x) => x.activeLang.value); 

  const params = useParams();

  const getCollection = useStore((x) => x.getCollection);
  const collection = useStore((x) => x.collection);
  const collectionResources = useStore((x) => x.collectionResources);
  const breadcrumbs = useStore((x) => x.breadcrumbs);

  const id = params.id;

  async function getData() {
    await getCollection(id);
  }

  useEffect(() => {
    setIsLoading(true);
    getData();

    const interval = setInterval(() => {
      setIsLoading(false);
      clearInterval(interval);
    }, 1000);
  }, []);

  const resourceSection = (
    <div className="shadow-xl px-5 pb-5 sm:pt-5 lg:min-h-[20vh]">
      {collectionResources &&
        collectionResources.length > 0 &&
        collectionResources.map((risorsa) => <Resource risorsa={risorsa} key={risorsa.ID} />)
      }
    </div>
  )

  const presentation = (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col p-5 py-10 lg:w-[60%] sm:w-[80%]">
        <h1 className="text-silver text-2xl mb-5">{activeLang ==='IT' ? collection?.nomeIt : collection?.nomeEn}</h1>

        <span className="text-silver text-lg">{activeLang ==='IT' ? collection?.descrizioneIt : collection?.descrizioneEn}</span>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />

      {presentation}

      {resourceSection}

      <PageLoading isLoading={isLoading} />
    </>
  );
};

export default OltrevitaCollection;

import React, { useEffect, useRef } from "react";

// STORE
import { useStore } from "../store/stores/store";

// COMPONENTS
import Card from "../components/Card.component";
import MuseoCard from "../components/Homepage/MuseoCard.component";
import HomeCustomNavbar from "../components/Navbar/HomeCustomNavbar.component";
import Carousel from "../components/ItemCarousel.component";

// IMAGES
import BackgroundImage from "../assets/images/staticImages/HomepageOrizzontale.webp";
import Collezioni from "../assets/images/staticImages/collezioni.webp";
import Opere from "../assets/images/staticImages/opere.webp";

// Museums images
import MuseoEgizio from "../assets/images/staticImages/MuseoEgizio.webp";
import MAO from "../assets/images/staticImages/MAO.webp";
import MuseoAntichità from "../assets/images/staticImages/museoAntichità.webp";

//import PageLoading from "../components/PageLoading.component";

const Homepage = () => {
  useEffect(() => {
    document.title = "Oltrevita - Homepage";
  }, []);

  const dictionary = useStore((x) => x.dictionary);

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView() 

  const homeCover = (
    <>
      <div
        className="h-[100vh] w-full items-center"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
        }}
      />
      <div className="absolute w-full h-[100vh] bg-[RGBA(3,3,3,0.70)] top-0">
        <HomeCustomNavbar />
        <div className="flex w-full h-full items-center">
          <div className="flex flex-col text-silver gap-3 pl-[5%] sm:pl-[10%] mt-[-30vh] sm:mt-[-20vh]">
            <h1 className="text-5xl font-semibold">{dictionary?.title}</h1>
            <span className="text-xl italic">{dictionary?.subtitle}</span>

            <button
              onClick={executeScroll}
              className="w-fit mt-[2vh] px-5 py-2 sm:py-3 rounded-lg shadow-md bg-secondary-dark text-silver transition ease-in-out delay-100 hover:bg-secondary"
            >
              <span>{dictionary?.homePage?.callToAction}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );

  const projectSection = (
    <div className="flex w-full justify-center">
      <div className="bg-primary w-full lg:w-[65%] py-10 px-5 flex justify-center text-silver text-center">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl">{dictionary?.homePage?.labelProgetto}</h2>
          <span className="text-lg mt-5">
            <span className="text-secondary">
              {dictionary.title}
            </span>{" "}
            {dictionary?.homePage?.descrizioneProgetto}
          </span>
        </div>
      </div>
    </div>
  );

  const museumSlideShow = (
    <div className="flex flex-col w-full bg-background-light py-10">
      <h2 className="text-3xl font-semibold text-center mb-5">
        {dictionary?.homePage?.museumsSection?.title}
      </h2>

      <div className="block sm:hidden">
        <Carousel gutter={0} hideArrows>
          <MuseoCard
            image={MuseoEgizio}
            link="https://www.museoegizio.it/"
            label="Museo Egizio di Torino"
          />
          <MuseoCard
            image={MuseoAntichità}
            link="https://museireali.beniculturali.it/"
            label="Musei Reali di Torino"
          />
          <MuseoCard
            label="Museo delle arti orientali di Torino"
            image={MAO}
            link="https://www.maotorino.it/it"
          />
        </Carousel>
      </div>

      <div className="hidden sm:flex w-full items-center justify-center gap-10">
        <MuseoCard
          image={MuseoEgizio}
          link="https://www.museoegizio.it/"
          label="Museo Egizio di Torino"
        />
        <MuseoCard
          image={MuseoAntichità}
          link="https://museireali.beniculturali.it/"
          label="Musei Reali di Torino"
        />
        <MuseoCard
          image={MAO}
          link="https://www.maotorino.it/it"
          label="Museo delle arti orientali di Torino"
        />
      </div>
    </div>
  );

  const linkSection = (
    <div
      className="flex flex-col md:flex-row w-full py-10 gap-5 justify-center bg-primary sm:px-10"
      ref={myRef}
    >
      <div className="flex flex-col w-full md:w-[50%] items-center justify-center">
        <h2 className="text-3xl font-semibold text-center text-silver">
          {dictionary?.homePage?.findsSection?.title}
        </h2>

        <span className="text-silver text-lg text-center mt-5 mx-5">
          {dictionary?.homePage?.findsSection?.description}
        </span>
      </div>

      <div className="flex flex-col sm:flex-row w-full md:w-[50%] items-center justify-center">
        <Card
          title={dictionary?.homePage?.findsSection?.cards?.collections?.title}
          image={Collezioni}
          link="/Reperti/Collezioni"
        />
        <Card
          title={dictionary?.homePage?.findsSection?.cards?.works?.title}
          image={Opere}
          link="/Reperti/Opere"
        />
      </div>
    </div>
  );

  return (
    <div>
      {homeCover}
      {projectSection}
      {museumSlideShow}
      {linkSection}
    </div>
  );
};

export default Homepage;

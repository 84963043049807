import React from "react";
import { useLocation } from "react-router-dom";

import MobileNavbar from "./MobileNavbar.component";
import DekstopNavbar from "./DekstopNavbar.component";

const Navbar = () => {
  const location = useLocation();

  //Customize navbar for Homepage
  return (
    <>
      {location.pathname !== "/" && (
        <>
          <div className="flex w-full h-[7vh] flex-row items-center sm:shadow-2xl sticky top-0 z-[50] bg-secondary-dark">
            <MobileNavbar navbarBg />
            <DekstopNavbar navbarBg />
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;

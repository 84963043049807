import { TEAM_ENG } from "./team";

export const PROJECTPAGE_ENG = {
  title: "Project",
  breadcrumbs: [
    { pageName: "Home", path: "/", active: false },
    { pageName: "The project", path: "/Progetto", active: true },
  ],
  team: TEAM_ENG,
  progettiCorrelatiTitle: "Related projects"
};

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// STORE
import { useStore } from "../store/stores/store";

// COMPONENTS
import Breadcrumb from "../components/Breadcrumb.component";
import PageLoading from "../components/PageLoading.component";
import Resource from "../components/Resource/Resource.component";

const OltrevitaObject = () => {
  const [isLoading, setIsLoading] = useState(false);

  const activeLang = useStore((x) => x.activeLang.value); 

  const params = useParams();

  const getUnit = useStore((x) => x.getUnit);
  const unit = useStore((x) => x.unit);
  const unitResources = useStore((x) => x.unitResources);
  const breadcrumbs = useStore((x) => x.breadcrumbsOpera);

  const id = params.id;

  async function getData() {
    await getUnit(id);
  }

  useEffect(() => {
    setIsLoading(true);
    getData();

    const interval = setInterval(() => {
      setIsLoading(false);
      clearInterval(interval);
    }, 1000);
  }, []);

  const resourceSection = (
    <div className="shadow-xl px-5 pb-5 sm:pt-5 lg:min-h-[20vh]">
      {unitResources &&
        unitResources.length > 0 ?
        unitResources.map((risorsa) => <Resource key={risorsa.ID} risorsa={risorsa} />)
        :
        (<div className="w-full flex justify-center">
        <img 
          src={process.env.REACT_APP_OBJECTS_IMAGES_URL + unit?.img} 
          alt={activeLang ==='IT' ? unit?.nomeIt : unit?.nomeEn} 
          className="sm:max-w-[600px] sm:max-h-[510px] rounded-sm"
        /></div>)
      }
    </div>
  )

  const presentation = (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col p-5 py-10 lg:w-[60%] sm:w-[80%]">
        <h1 className="text-silver text-2xl mb-5">{activeLang ==='IT' ? unit?.nomeIt : unit?.nomeEn}</h1>

        <span className="text-silver text-lg">{activeLang ==='IT' ? unit?.descrizioneIt : unit?.descrizioneEn}</span>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />

      {presentation}

      {resourceSection}

      <PageLoading isLoading={isLoading} />
    </>
  );
};

export default OltrevitaObject;

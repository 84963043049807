import React, { useState } from 'react';
import { pdfjs } from "react-pdf";

const TestPage = () => {

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const audioTest = {url: 'AudioMuseiReali.mov', title: 'Audio musei reali', author: 'Autore name'}

  return (
    <>
      <div className="flex flex-col items-center justify-center bg-background-light">

          {/* <AudioPlayer audio={audioTest}/> */}
        
      </div>
    </>
  );
};

export default TestPage;

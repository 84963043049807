import React from "react";

const TextArea = ({
  componentRef = null,
  type = "text",
  placeholder,
  icon,
  name,
  value,
  onChange = () => {},
  variant,
  label,
  autofocus = false,
  onBlur = () => {},
  isValid = null,
  isInvalid = null,
  disabled = false,
  classes,
  darkMode = false,
}) => {
  const input = (
    <div
      className={`flex flex-row items-center rounded-md px-5 py-[2vh] gap-4 relative w-full h-[15vh] shadow-none
      } ${isValid && "border-green"} ${isInvalid && "border-red"} ${
        disabled
          ? "cursor-not-allowed bg-gray"
          : darkMode
          ? "bg-[#434343]"
          : "bg-white border-2 border-primary-light"
      }`}
    >
      {icon}
      <textarea
        ref={componentRef}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onBlur={(event) => onBlur(event.target.value)}
        autoFocus={autofocus}
        name={name}
        disabled={disabled}
        className={`outline-none w-full h-full ${
          disabled
            ? "cursor-not-allowed bg-gray"
            : darkMode
            ? "bg-[#434343] text-silver"
            : "bg-white"
        }`}
      />
    </div>
  );

  return (
    <div className={classes}>
      {label && (
        <span
          className={`${isInvalid && "text-red"} ${darkMode && "text-silver"} `}
        >
          {label}
        </span>
      )}
      {input}
    </div>
  );
};

export default TextArea;

import { useRef, useState } from 'react';

// import components
import DisplayTrack from './DisplayTrack';
import Controls from './Controls';
import ProgressBar from './ProgressBar';

const AudioPlayer = ({audio}) => {

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();

  return (
    <div className="w-full flex flex-col justify-center items-center">
       <div className="w-full p-5 sm:w-[50%] mb-[5vh]">
          <DisplayTrack
            {...{
              audio,
              audioRef,
              setDuration,
              progressBarRef
            }}
          />
          
          <ProgressBar
            {...{ progressBarRef, audioRef, timeProgress, duration }}
          /><Controls
            {...{
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress
            }}
          />
        </div>
        </div>
  );
};
export default AudioPlayer;
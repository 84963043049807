import VitoMessina from "../../../assets/images/team/VitoMessina.jpg";
import FabianaVernero from "../../../assets/images/team/FabianaVernero.jpg";
import AlessandroMancuso from "../../../assets/images/team/AlessandroMancuso.jpg";
import AlessandraCellerino from "../../../assets/images/team/AlessandraCellerino.jpg";
import EnricoFoietta from "../../../assets/images/team/EnricoFoietta.jpg";
import CédricGobeil from "../../../assets/images/team/CédricGobeil.jpg";
import JohannesAuenmüller from "../../../assets/images/team/JohannesAuenmüller.jpg";
import ElisaPanero from "../../../assets/images/team/ElisaPanero.jpg";
import RosarioMariaAnzalone from "../../../assets/images/team/RosarioMariaAnzalone.jpg";
import ClaudiaRamasso from "../../../assets/images/team/ClaudiaRamasso.jpg";
import GiorgiaCalamusa from "../../../assets/images/team/GiorgiaCalamusa.jpg";
import MauroComo from "../../../assets/images/team/MauroComo.jpg";
import KaterinaCarlottaKoukzelas from "../../../assets/images/team/KaterinaCarlottaKoukzelas.jpg";
import NicasioLepore from "../../../assets/images/team/NicasioLepore.jpg";
import ElenaVasirani from "../../../assets/images/team/ElenaVasirani.jpg";
import MonicaCini from "../../../assets/images/team/MonicaCini.jpg";
import SilviaGiordano from "../../../assets/images/team/SilviaGiordano.jpg";

export const TEAM_ITA = [
  {
    fullName: "Vito Messina",
    role: "Direttore scientifico",
    foundation: "Università di Torino",
    image: VitoMessina,
    link: "https://www.dipstudistorici.unito.it/do/docenti.pl/Alias?vito.messina#tab-profilo",
  },
  {
    fullName: "Fabiana Vernero",
    role: "Codirettrice scientifica",
    foundation: "Università di Torino",
    image: FabianaVernero,
    link: "https://informatica.unito.it/do/docenti.pl/Alias?fabiana.vernero#tab-profilo",
  },
  {
    fullName: "Alessandra Cellerino",
    role: "Archeologa esperta",
    foundation: "Università di Torino",
    image: AlessandraCellerino,
    link: "https://universitaditorino.academia.edu/AlessandraCellerino",
  },
  {
    fullName: "Alessandro Mancuso",
    role: "Web developer & designer",
    foundation: "Università di Torino",
    image: AlessandroMancuso,
    link: "https://www.linkedin.com/in/alessandro-mancuso-5678681bb/",
  },
  {
    fullName: "Enrico Foietta",
    role: "Archeologo esperto",
    foundation: "Università di Torino",
    image: EnricoFoietta,
    link: "https://unito.academia.edu/EnricoFoietta",
  },
  {
    fullName: "Cédric Gobeil",
    role: "Curatore",
    foundation: "Museo Egizio Torino",
    image: CédricGobeil,
    link: "https://museoegizio.it/chi-siamo/uffici/",
  },
  {
    fullName: "Johannes Auenmüller",
    role: "Curatore",
    foundation: "Museo Egizio Torino",
    image: JohannesAuenmüller,
    link: "https://museoegizio.it/chi-siamo/uffici/",
  },
  {
    fullName: "Elisa Panero",
    role: "Curatrice",
    foundation: "Musei Reali Torino",
    image: ElisaPanero,
    link: "https://beniculturali.academia.edu/ElisaPanero",
  },
  {
    fullName: "Rosario Maria Anzalone",
    role: "Curatore",
    foundation: "Musei Reali Torino",
    image: RosarioMariaAnzalone,
    link: "https://beniculturali.academia.edu/RosarioMariaAnzalone",
  },
  {
    fullName: "Claudia Ramasso",
    role: "Curatrice",
    foundation: "MAO-Museo d’Arte Orientale Torino",
    image: ClaudiaRamasso,
    link: "https://www.maotorino.it/it/staff-0",
  },
  {
    fullName: "Giorgia Calamusa",
    role: "Archeologa",
    foundation:
      "Scuola di Specializzazione in Beni Archeologici Giorgio Gullini",
    image: GiorgiaCalamusa,
    link: "https://unito.academia.edu/GiorgiaCalamusa",
  },
  {
    fullName: "Mauro Como",
    role: "Archeologo",
    foundation:
      "Scuola di Specializzazione in Beni Archeologici Giorgio Gullini",
    image: MauroComo,
    link: null,
  },
  {
    fullName: "Katerina Carlotta Koukzelas",
    role: "Archeologa",
    foundation:
      "Scuola di Specializzazione in Beni Archeologici Giorgio Gullini",
    image: KaterinaCarlottaKoukzelas,
    link: "https://www.researchgate.net/profile/Katerina-Koukzelas",
  },
  {
    fullName: "Nicasio Lepore",
    role: "Archeologo",
    foundation:
      "Scuola di Specializzazione in Beni Archeologici Giorgio Gullini",
    image: NicasioLepore,
    link: null,
  },
  {
    fullName: "Elena Vasirani",
    role: "Archeologa",
    foundation:
      "Scuola di Specializzazione in Beni Archeologici Giorgio Gullini",
    image: ElenaVasirani,
    link: "https://it.linkedin.com/in/elena-vasirani-6727511b0",
  },
  {
    fullName: "Monica Cini",
    role: "Manager del progetto",
    foundation: "Università di Torino",
    image: MonicaCini,
    link: "https://www.University of Torino.it/persone/monica.cini",
  },
  {
    fullName: "Silvia Giordano",
    role: "Manager del progetto",
    foundation: "Università di Torino",
    image: SilviaGiordano,
    link: "https://webapps.University of Torino.it/rubrica/rubricaRisultati.php?tipoRic=mat&matricola=082225",
  },
];

import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

// STORE
import { useStore } from "../../store/stores/store";

// COMPONENTS
import MobileMenu from "./MobileMenu.component";

//LOGO
import Logo from "../../assets/images/logo/logo1.png";

const MobileNavbar = () => {
  const dictionary = useStore((x) => x.dictionary);

  const windowHeight = useRef(window.innerHeight);
  const navigate = useNavigate();

  const MOBILE_ROW_CSS = "flex flex-1 items-center";

  const handleNavigate = (path) => {
    navigate(path);
  };

  const menuIcon = (
    <>
      <div className={MOBILE_ROW_CSS + " h-full justify-start"}>
        <MobileMenu links={dictionary?.links} onNavigate={handleNavigate} height={windowHeight.current} />
      </div>
    </>
  );

  const logoMobile = (
    <div className="flex h-full justify-center items-center">
      <img
        src={Logo}
        className="w-[100px] h-[30px]"
        alt="Logo Oltrevita"
        onClick={() => handleNavigate("/")}
      />
    </div>
  );

  return (
    <div className="flex lg:hidden w-full h-full items-center px-5">
      {menuIcon}
      {logoMobile}
    </div>
  );
};

export default MobileNavbar;

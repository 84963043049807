export const HOMEPAGE_ENG = {
  labelProgetto: "Afterlife",
  descrizioneProgetto:
    "introduces a new storytelling paradigm that tells the present via objects of the past. The latter objects have been entangled with those who made and used them in antiquity, but they are likewise entangled with us in contemporaneity, so that they become, sometimes, symbols of the present-day. Scan the QR codes displayed in Museums near objects of the past to see listen and read today’s stories that still see them as active players.",
  callToAction: "Discover the artifacts",
  museumsSection: {
    title: "The Museums",
  },
  findsSection: {
    title: "The Finds",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Scan the QR codes displayed in Museums near objects of the past to see listen and read today’s stories that still see them as active players.",
    cards: {
      collections: { title: "The Collections" },
      works: { title: "The Works" },
    },
  },
};

import React, { useState, useEffect } from "react";

// COMPONENTS
import MobileNavbar from "./MobileNavbar.component";
import DekstopNavbar from "./DekstopNavbar.component";

const HomeCustomNavbar = () => {
  const [navbarBg, setNavbarBg] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbarBg(true);
    } else {
      setNavbarBg(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <div className={`flex w-full h-[7.5vh] z-[999] sticky top-0
        ${navbarBg ? "bg-secondary-dark shadow-2xl" : "bg-transparent"}`}
    >
        <MobileNavbar />
        <DekstopNavbar navbarBg={navbarBg} />
    </div>
  );

};

export default HomeCustomNavbar;

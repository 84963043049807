import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const unitaApi = {

  getUnits: async (filters, numberOfPage, itemsPerPage) => {
    try {
      let request = `${url}/unita-api.php?numberOfPage=` + numberOfPage + `&itemsPerPage=` + itemsPerPage;

      // if(filters?.unitName || filters?.collection || filters?.museum){
      //   request += "?filters=true";
        if(filters?.unitName) request = request + `&name=` + filters.unitName;
        if(filters?.collection?.id) request = request + `&collectionId=` + filters.collection?.id;
        if(filters?.museum?.id) request += `&museumId=` + filters.museum?.id;
      // } 

      const response = await axios.get(request);
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  getUnit: async (id) => {
    try {
      const response = await axios.get(
        `${url}/unita-api.php?ID=${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  createUnit: async (data) => {
    const response = axios
      .post(url + "unita-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

export default unitaApi;

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

// COMPONENTS
import Flag from "react-world-flags";

//LOGO
import Logo from "../../assets/images/logo/logo1.png";

// STORE
import { useStore } from "../../store/stores/store";

const DekstopNavbar = ({ navbarBg = false }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLangMenu, setShowLangMenu] = useState(false);

  const dictionary = useStore((x) => x.dictionary);
  const activeLang = useStore((x) => x.activeLang);
  const setLanguage = useStore((x) => x.setLanguage);

  const navigate = useNavigate();
  const location = useLocation();

  const MOBILE_ROW_CSS = "flex flex-1 items-center";

  const languages = [
    { name: "Italiano", label: "Italiano", value: "IT" },
    { name: "Inglese", label: "English", value: "GB" },
  ];

  const handleNavigate = (path) => {
    setShowDropdown(false);
    navigate(path);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleLangMenu = () => {
    setShowLangMenu(!showLangMenu);
  };

  const changeLang = (lang) => {
    setShowLangMenu(false);
    setLanguage(lang);
  };

  const logoDekstop = (
    <button
      className="w-[25vh] flex items-center justify-center"
      onClick={() => handleNavigate("/")}
    >
      <img src={Logo} className="w-[15vh] h-[4vh]" alt="Logo Oltrevita" />
    </button>
  );

  const NavbarDropdownMenu = (link) => (
    <div className="w-[15vh] h-[10vh] absolute">
      <div className="bg-secondary-dark mt-2 z-50 rounded-lg">
        {link.options &&
          link.options.length > 0 &&
          link.options.map((option) => (
            <button
              className="flex items-start w-full p-3 hover:bg-secondary rounded-lg"
              key={option.pageName}
              onClick={() => handleNavigate(option.path)}
            >
              <span className="text-silver">{option.pageName}</span>
            </button>
          ))}
      </div>
    </div>
  );

  const linkColor = (link) => {
    return link.path === location.pathname
      ? navbarBg
        ? "text-silver underline underline-offset-8"
        : "text-secondary"
      : navbarBg
      ? "text-silver transition ease-in-out delay-100 hover:underline underline-offset-8"
      : "text-silver transition ease-in-out delay-100 hover:text-secondary";
  };

  const addLink = (link) => {
    if (!link.isHidden) {
      return (
        <div key={link.pageName}>
          {!link.isDropdown ? (
            <button
              className={`w-[10vh] lg:w-[15vh] h-full flex items-center justify-center cursor-pointer font-semibold 
              ${linkColor(link)}
              `}
              onClick={() => handleNavigate(link.path)}
            >
              <span>{link.pageName}</span>
            </button>
          ) : (
            <>
              <button
                className={`w-[10vh] lg:w-[15vh] h-[100%] flex items-center justify-center cursor-pointer text-silver font-semibold 
                ${linkColor(link)}
              `}
                onClick={toggleDropdown}
              >
                <span>{link.pageName}</span>
                <FontAwesomeIcon
                  icon={!showDropdown ? faChevronDown : faChevronUp}
                  size="sm"
                  className="ml-2 mt-1"
                />
              </button>
              {showDropdown && NavbarDropdownMenu(link)}
            </>
          )}
        </div>
      );
    }
  };

  const langDropdownMenu = (
    <div className="w-[15vh] h-[10vh] mt-[17vh] absolute">
      <div className="bg-secondary-dark mt-2 z-50 rounded-lg">
        {languages.map((lang) => (
          <button
            className="flex items-center w-full p-3 hover:bg-secondary rounded-lg"
            onClick={() => changeLang(lang)}
            key={lang.value}
          >
            <Flag
              code={lang.value}
              alt={lang.label}
              className="h-[10px] w-[17px] mr-2 mt-1"
            />
            <span className="text-silver">{lang.label}</span>
          </button>
        ))}
      </div>
    </div>
  );

  const languageMenu = (
    <div className={MOBILE_ROW_CSS + " h-full justify-end mr-[2vh]"}>
      <button
        className={`flex items-center w-[7vh] lg:w-[15vh] justify-center h-full cursor-pointer text-silver 
          ${
            navbarBg
              ? "transition ease-in-out delay-100 hover:underline"
              : "transition ease-in-out delay-100 hover:text-secondary"
          } 
        `}
        onClick={toggleLangMenu}
      >
        <Flag
          code={activeLang.value}
          alt={activeLang.label}
          className="h-[10px] w-[15px] mt-1"
        />
        <span className="mx-2">{activeLang.label}</span>
        <FontAwesomeIcon
          icon={showLangMenu ? faChevronUp : faChevronDown}
          size="sm"
          className="mt-1"
        />
      </button>
      {showLangMenu && langDropdownMenu}
    </div>
  );

  return (
    <div className="hidden lg:flex sm:flex-row w-full h-full sm:justify-between lg:justify-start">
      {logoDekstop}
      {dictionary &&
        dictionary.links &&
        dictionary.links.length > 0 &&
        dictionary.links.map((link) => addLink(link))}
      {languageMenu}
    </div>
  );
};

export default DekstopNavbar;

import React from "react";
import { useNavigate } from "react-router-dom";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChevronRight, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

const Breadcrumb = ({
  breadcrumbs = [{ pageName: "Home", path: "/", active: true }],
}) => {
  const navigate = useNavigate();

  const linkClass = "cursor-pointer hover:underline";
  const activeClass = "text-secondary cursor-default";

  return (
    <div className="flex w-full h-[7vh] bg-primary-light justify-center items-center text-silver">
      <div className="flex w-full lg:w-[60%] sm:w-[80%] p-5 sm:p-10 overflow-auto">
        {breadcrumbs.map((page) =>
          page?.active ? (
            <div key={page.pageName} className="whitespace-nowrap">
              <span className={activeClass}>{page.pageName}</span>
            </div>
          ) : page.pageName === "Home" ? (
            <div key={page.pageName} className="whitespace-nowrap">
              <button
                href="#"
                aria-label={page.pageName}
                className={linkClass + " hover:opacity-75"}
                onClick={() => navigate(page.path)}
              >
                <FontAwesomeIcon icon={faHome} size="sm" />
              </button>
              <FontAwesomeIcon className="cursor-default mx-3 mt-1" icon={faAngleDoubleRight} size="sm" />
            </div>
          ) : (
            <div className="whitespace-nowrap" key={page.pageName} >
              <button
                href="#"
                aria-label={page.pageName}
                className={linkClass}
                onClick={() => navigate(page.path)}
              >
                {page.pageName}
              </button>
              <FontAwesomeIcon className="cursor-default mx-3 mt-1" icon={faAngleDoubleRight} size="sm" />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;

// IMAGES
import LogoUniTo from "../../../assets/images/partners/LogoUniTo.png";
import LogoCRT from "../../../assets/images/partners/LogoFondazioneCRT.png";

import LogoME from "../../../assets/images/partners/LogoME.png";
import LogoMRT from "../../../assets/images/partners/LogoMRT.png";
import LogoMAO from "../../../assets/images/partners/LogoMAO.png";
import LogoStudiStorici from "../../../assets/images/partners/LogoStudiStorici.png";
import LogoDI from "../../../assets/images/partners/LogoDI.png";
import LogoScuolaGullini from "../../../assets/images/partners/LogoScuolaGullini.png";

export const PARTNERS_ITA = {
  founders: [
    {
      name: "Università di Torino",
      image: LogoUniTo,
      link: "https://www.unito.it/ ",
      founder: true,
    },
    {
      name: "Fondazione CRT",
      image: LogoCRT,
      link: "https://www.fondazionecrt.it/ ",
      founder: true,
    },
  ],
  partners: [
    {
      name: "Museo Egizio",
      image: LogoME,
      link: "https://www.museoegizio.it/",
    },
    {
      name: "Musei Reali Torino",
      image: LogoMRT,
      link: "https://museireali.beniculturali.it/ ",
    },
    {
      name: "Museo delle arti orientali",
      image: LogoMAO,
      link: "https://www.maotorino.it/it",
    },
    {
      name: "Dipartimento di studi storici, Università di Torino",
      image: LogoStudiStorici,
      link: "https://www.dipstudistorici.unito.it/do/home.pl",
    },
    {
      name: "Dipartimento di informatica, Università di Torino",
      image: LogoDI,
      link: "http://www.di.unito.it/do/home.pl",
    },
    {
      name: "Scuola di specializzazione in beni archeologici Giorgio Gullini",
      image: LogoScuolaGullini,
      link: "https://scuolaarcheologia.campusnet.unito.it/do/home.pl ",
    },
  ],
};

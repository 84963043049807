import React, { useEffect, useState, useRef } from "react";
import emailjs from '@emailjs/browser';

// COMPONENTS
import Breadcrumb from "../components/Breadcrumb.component";
import Pagetitle from "../components/Pagetitle.component";
import Button from "../components/Button.component";
import Input from "../components/Input.component";
import Textarea from "../components/Textarea.component";

// STORE
import { useStore } from "../store/stores/store";
import LogoPartner from "../components/LogoPartner.component";

const initialValues = {
  nome: "",
  contatto: "",
  descrizione: "",
};

const PartnersPage = () => {
  const [filters, setFilters] = useState({ ...initialValues });

  const form = useRef();

  const EMAILJS_PUBLIC_KEY = "bxWeObbKAW2VTbPSX";
  const EMAILJS_TEMPLATE_ID = "template_36jvutg";
  const EMAILJS_SERVICE_ID = "service_6x8b8np";

  useEffect(() => {
    document.title = "Oltrevita - I partners";
  }, []);

  const dictionary = useStore((x) => x.dictionary);

  const resetForm = () => {
    setFilters({ ...initialValues });
  };

  const handleFilter = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, form.current, EMAILJS_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  const partnersSection = (
    <div className="flex flex-col w-full px-5 py-10 bg-background-light text-primary items-center">
      <div className="flex flex-col w-full lg:w-[60%] sm:w-[80%] ">
        <div className="flex flex-wrap w-full justify-between">
          {dictionary?.partners?.partners.map((partner) => (
            <LogoPartner
              image={partner.image}
              alt={partner.name + " Logo"}
              link={partner.link}
              key={partner.page}
            />
          ))}
        </div>
      </div>
    </div>
  );

  const joinUsSection = (
    <div className="flex flex-col w-full h-auto items-center px-5 py-10 text-silver">
      <div className="flex flex-col lg:w-[60%] sm:w-[80%] items-center">
        <div className="flex w-full items-center py-5">
          <h2 className="text-xl font-semibold min-w-[18vh]">
            {dictionary?.partnersPage?.joinUsTitle}
          </h2>
          <hr className="w-full text-primary-light" />
        </div>

        <div className="flex flex-col w-full h-auto justify-center pt-5 gap-3">
          <span className="text-lg">{dictionary?.partnersPage?.joinUs1}</span>
          <span className="text-lg">{dictionary?.partnersPage?.joinUs2}</span>

          <form ref={form} onSubmit={sendEmail}>
            <div className="flex flex-col gap-3 mt-5 text-primary">
              <div className="flex flex-col w-full gap-3 sm:flex-row">
                <Input
                  label={dictionary?.partnersPage?.nameField}
                  placeholder={
                    dictionary?.partnersPage?.nameFieldPlaceholder
                  }
                  name="user_name"
                  classes="w-full sm:w-[50%]"
                  darkMode
                />
                <Input
                  label={dictionary?.partnersPage?.contactField}
                  placeholder={
                    dictionary?.partnersPage?.contactFieldPlaceholder
                  }
                  name="user_contact"
                  classes="w-full sm:w-[50%]"
                  darkMode
                />
              </div>

              <Textarea
                label={dictionary?.partnersPage?.descField}
                placeholder={dictionary?.partnersPage?.descFieldPlaceholder}
                name="message"
                darkMode
              />
            </div>

            <div className="flex w-full justify-end gap-5 mt-5">
              <Button variant={3} type="submit">
                {dictionary?.partnersPage?.resetButton}
              </Button>
              <Button variant={2} onClick={resetForm}>
                {dictionary?.partnersPage?.sendButton}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={dictionary?.partnersPage?.breadcrumbs} />
      <div className="w-full flex flex-col items-center">
        <Pagetitle
          title="Partners"
          description={dictionary?.partnersPage?.description}
        />
        {partnersSection}
        {joinUsSection}
      </div>
    </>
  );
};

export default PartnersPage;

import React from "react";
import ImageGallery from 'react-image-gallery';

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const Gallery = ({ images = [], mobile = false, risorsa = {} }) => {

  const renderLeftNav = (onClick, disabled) => (
    <FontAwesomeIcon icon={faChevronCircleLeft} className="text-silver image-gallery-icon image-gallery-left-nav" onClick={onClick} disabled={disabled} size="2xl" />
   );

   const renderRightNav = (onClick, disabled) => (
    <FontAwesomeIcon icon={faChevronCircleRight} className="text-silver image-gallery-icon image-gallery-right-nav" onClick={onClick} disabled={disabled} size="2xl" />
   )

  return (
    <>
     <div className="w-full flex flex-col items-center justify-center py-5">
      <span className="text-secondary text-2xl font-semibold mb-5">La galleria fotografica</span>
        <div className="w-full sm:w-[50%]">
          <ImageGallery 
            items={images.map(el => ({original: process.env.REACT_APP_GALLERIA_IMAGES_URL + el?.url, thumbnail: process.env.REACT_APP_GALLERIA_IMAGES_URL + el?.url}))}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            />
        </div>
      </div>
    </>
  );
};

export default Gallery;

import React from "react";
import { useNavigate } from "react-router-dom";


const Card = ({
  onClick = () => {},
  disabled = false,
  variant,
  classes,
  children,
  title,
  subtitle,
  link,
  image,
  ...props
}) => {

  const navigate = useNavigate();

  return (
    <button
      className="w-full sm:w-[350px] h-[350px] flex flex-col bg-primary shadow-xl sm:hover:opacity-60 cursor-pointer rounded-sm"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
      }}
      onClick={() => navigate(link)}
      aria-label={title}
    >
      <div className="flex w-full h-full bg-gradient-to-b from-transparent via-transparent to-primary justify-center items-end">
        <div className="flex flex-col justify-center items-center">
          <span className="text-silver text-2xl pb-3">{title}</span>
          <span className="text-silver pb-10">
            {subtitle}
          </span>
        </div>
      </div>
    </button>
  );
};

export default Card;

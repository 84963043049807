import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const museoApi = {

  getMuseums: async (filters) => {
    try {
      let request = `${url}/museo-api.php`;

      if(filters?.museumName) request = request + `?name=` + filters.museumName;

      const response = await axios.get(request);
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  getMuseum: async (id) => {
    try {
      const response = await axios.get(
        `${url}/museo-api.php?ID=${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  createMuseum: async (data) => {
    const response = axios
      .post(url + "/museo-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  updateMuseum: async (data) => {
    const response = axios
      .post(url + "/museo-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  deleteMuseum: async (data) => {
    const response = axios
      .post(url + "/museo-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

};

export default museoApi;

import { create } from "zustand";
import collectionsStore from "./collections.store";
import languageStore from "./language.store";
import museumsStore from "./museums.store";
import unitsStore from "./units.store";

export const useStore = create((...x) => ({
  ...languageStore(...x),
  ...museumsStore(...x),
  ...collectionsStore(...x),
  ...unitsStore(...x),
}));

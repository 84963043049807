import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { useLocation } from "react-router-dom";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faClose,
  faChevronUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

// COMPONENTS
import Flag from "react-world-flags";

//LOGO
import Logo from "../../assets/images/logo/logo1.png";

// STORE
import { useStore } from "../../store/stores/store";

import style from "../style.css";

const MobileMenu = ({ links, onNavigate = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLangMenu, setShowLangMenu] = useState(false);

  const location = useLocation();

  const dictionary = useStore((x) => x.dictionary);
  const activeLang = useStore((x) => x.activeLang);
  const setLanguage = useStore((x) => x.setLanguage);

  const languages = [
    { name: "Italiano", label: "Italiano", value: "IT" },
    { name: "Inglese", label: "English", value: "GB" },
  ];

  useEffect(() => {
    isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [isOpen]);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleLangMenu = () => {
    setShowLangMenu(!showLangMenu);
  };

  const changeLang = (lang) => {
    setShowLangMenu(false);
    setLanguage(lang);
  };

  const handleNavigation = (path) => {
    closeMenu();
    onNavigate(path);
  };

  const langMenu = (
    <div className="w-[150px] h-[10vh] mt-[17vh] absolute">
      <div className="bg-silver mt-2 z-50 rounded-lg">
        {languages.map((lang) => (
          <div
            className="flex items-center w-full p-3 text-primary"
            onClick={() => changeLang(lang)}
            key={lang.value}
          >
            <div className="flex flex-1">
              <Flag code={lang.value} className="h-[10px] w-[17px] mr-2 mt-2" />
              <span>{lang.label}</span>
            </div>

            {lang.value === activeLang?.value && (
              <FontAwesomeIcon icon={faCheck} size="sm" className="mt-1" />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const languageMenu = (
    <div>
      <div
        className="flex items-center justify-center h-full cursor-pointer text-silver mr-3"
        onClick={toggleLangMenu}
      >
        <Flag code={activeLang.value} className="h-[10px] w-[18px] mt-1" />
        <span className="mx-2">{activeLang.label}</span>
        <FontAwesomeIcon
          icon={showLangMenu ? faChevronUp : faChevronDown}
          size="sm"
          className="mt-1"
        />
        {showLangMenu && langMenu}
      </div>
    </div>
  );

  const addLink = (link) => {
    if (!link.isHidden) {
      return (
        <div key={link.pageName}>
          {!link.isDropdown ? (
            <div
              className="w-full h-[8vh] flex items-start py-7 text-xl justify-center"
              onClick={() => handleNavigation(link.path)}
            >
              <span
                className={`${
                  link.path === location.pathname
                    ? "text-silver underline underline-offset-8"
                    : "text-silver"
                } `}
              >
                {link.pageName}
              </span>
            </div>
          ) : (
            <>
              {link?.options.map((option) => (
                <div
                  className="w-full h-[8vh] flex items-start py-7 text-xl justify-center"
                  key={option.pageName}
                  onClick={() => handleNavigation(option.path)}
                >
                  <span
                    className={`${
                      option.path === location.pathname
                        ? "text-silver underline underline-offset-8"
                        : "text-silver"
                    } `}
                  >
                    {option.pageName}
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faBars}
        size="xl"
        color="#ecebff"
        onClick={openMenu}
      />
      <Menu isOpen={isOpen} onStateChange={handleStateChange}>
      {isOpen && <div className={`absolute top-0 left-0 w-full h-[100vh] bg-[RGBA(3,3,3,0.80)] scrolling-y-auto`} />}
        <div
          className={`fixed top-0 left-0 flex flex-col items-start w-full max-w-[500px] h-[100vh] bg-secondary-dark p-8 scrolling-y-auto`}
        >
          <div className="flex w-full">
            <div className="flex-1">
              <FontAwesomeIcon
                icon={faClose}
                size="xl"
                color="#ecebff"
                onClick={closeMenu}
              />
            </div>
            {languageMenu}
          </div>

          <div className="w-full flex flex-col my-[5vh] text-lg">
            {links &&
              links.length > 0 &&
              links.map((link) => addLink(link))}
          </div>

          <div className="flex flex-col w-full items-center pt-5">
            <img src={Logo} className="w-[100px] h-[30px]" alt="Logo Oltrevita" />
            <span className="text-silver text-center italic mt-2">
              {dictionary?.subtitle}
            </span>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default MobileMenu;

import React from "react";
import { pdfjs } from "react-pdf";
import { useStore } from "../../store/stores/store";

// ICONS
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Zoom from "react-img-hover-zoom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Documento = ({ risorsa, ...props }) => {
  const activeLang = useStore((x) => x.activeLang.value);

  const dictionary = useStore((x) => x.dictionary);

  return (
    <div className=" w-full flex flex-col items-center justify-center mb-5 sm:mt-5 h-auto">
      <div className="w-full lg:w-[60%] sm:w-[80%]">
        <span className="text-secondary text-lg font-semibold ">
          {activeLang === "IT" ? risorsa.titoloIt : risorsa.titoloEn}
        </span>

        <div className="flex flex-col sm:flex-row gap-5 sm:h-[30vh] mt-2 sm:mt-5">
          <div className="w-full py-5 sm:py-0 sm:w-[200px] h-full justify-center gap-2 px-2 flex flex-col ">
            <div className="w-full flex items-center justify-center cursor-zoom-in rounded-sm overflow-hidden">
              <Zoom
                img={
                  process.env.REACT_APP_DOC_IMAGES_URL + risorsa?.image
                }
                width={200}
                height={250}
                zoomScale={2}
                style={{ width: "100%", height: "100%" }}
                alt={risorsa.image}
              />
            </div>
          </div>
          <div className="w-full sm:p-0 sm:w-[75%] flex flex-col h-full justify-center gap-2">
            <span className="text-silver text-sm font-semibold">
              {risorsa.autore}
            </span>
            <span className="text-silver text-sm mt-2">
              {risorsa.sottotitolo}
            </span>

            <span className="text-silver text-md mt-3">
              {activeLang === "IT"
                ? risorsa.descrizioneIt
                : risorsa.descrizioneEn}
            </span>

            {risorsa?.url && (
              <a
                href={process.env.REACT_APP_RESOURCES_URL + risorsa.url}
                download="Bando ordinarie 2021"
                target="_blank"
                rel="noreferrer"
                className="pt-3 sm:pt-0"
              >
                <button className="flex h-[5vh] w-fit items-center gap-3 text-silver hover:text-secondary">
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="xl"
                    className="cursor-pointer"
                  />
                  <span className="text-lg">
                    {dictionary.language === "IT"
                      ? "Scarica fumetto"
                      : "Download comics"}
                  </span>
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documento;

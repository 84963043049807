import axios, * as others from "axios";

const url = process.env.REACT_APP_API_URL;

const risorsaApi = {
  getResources: async () => {
    try {
      const response = await axios.get(`${url}/risorsa-api.php`);
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  getResource: async (id) => {
    try {
      const response = await axios.get(
        `${url}/risorsa-api.php?ID=${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  createResource: async (data) => {
    axios
      .post(url + "risorsa-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

export default risorsaApi;

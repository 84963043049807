import React from "react";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";

import { faEnvelope, faCamera } from "@fortawesome/free-solid-svg-icons"; 

//LOGO
import Logo from "../assets/images/logo/logo1.png";

// STORE
import { useStore } from "../store/stores/store";

const Footer = () => {
  const dictionary = useStore((x) => x.dictionary);

  const imgDivClass = "flex items-center justify-center w-[180px] h-[60px]";
  const imageClass =
    "w-[150px] h-[50px] cursor-pointer hover:w-[180px] hover:h-[60px]";

  const partnersSection = (
    <div className="hidden lg:flex w-full h-[10vh] items-center justify-center bg-background-light">
      {dictionary?.partners?.founders.map((partner) => (
        <a
          href={partner.link}
          className={imgDivClass}
          key={partner.name}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={partner.image}
            className={imageClass}
            alt={partner.name + " Logo"}
          />
        </a>
      ))}
      {dictionary?.partners?.partners.map((partner) => (
        <a
          href={partner.link}
          className={imgDivClass}
          key={partner.name}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={partner.image}
            className={imageClass}
            alt={partner.name + " Logo"}
          />
        </a>
      ))}
    </div>
  );

  return (
    <footer className="flex flex-col">
      <div className="flex flex-col items-center bg-primary-light p-5">
        <div className="flex flex-col w-[90%] sm:flex-row items-center justify-between sm:gap-5">
          <div className="flex flex-col w-full sm:w-[30%] items-center py-10 ">
            <img src={Logo} className="w-[14vh] h-[4vh]" alt="Logo Oltrevita" />
            <span className="text-silver italic mt-2 text-center">
              {dictionary?.subtitle}
            </span>
          </div>

          <hr className="w-full text-silver opacity-50 sm:hidden" />

          <div className="flex w-full sm:w-[30%] items-center py-8">
            <div className="flex flex-col flex-1 h-[20vh] sm:h-[25vh] gap-2 text-silver items-center justify-center sm:pt-12 sm:justify-start sm:items-start ">
              <span className="text-lg font-semibold">
                {dictionary?.footer?.aboutUs}
              </span>
              <span className="sm:text-lg text-center sm:text-start">
                {dictionary?.footer?.aboutUsText}
              </span>
            </div>
          </div>

          <hr className="w-full text-silver opacity-50 sm:hidden" />

          <div className="flex w-full sm:w-[30%] items-center py-5">
            <div className="flex flex-col flex-1 h-[15vh] sm:h-[25vh] gap-2 text-silver items-center justify-center sm:pt-12 sm:justify-start sm:items-start">
              <span className="text-lg font-semibold">
                {dictionary?.footer?.contactUs}
              </span>
              <div className="flex flex-col text-center sm:text-start">
                <span className="sm:text-lg">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    key="mail"
                    color="#ecebff"
                    className="mr-2"
                  />
                  {"oltrevita@unito.it"}
                </span>
                <span className="sm:text-lg">
                  <FontAwesomeIcon
                    icon={faCamera}
                    key="mail"
                    color="#ecebff"
                    className="mr-2"
                  />
                  {"gaetanogambino@ymail.com"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr className="w-[95%] text-silver opacity-50" />

        <div className="flex flex-col-reverse sm:flex-row w-full sm:w-[80%] justify-between pt-5">
          <div className="flex flex-col sm:flex-row items-center">
            <span className="text-xs text-center sm:text-md text-silver mt-3">
              Copyright &copy; {new Date().getFullYear()} Oltrevita - Afterlife.
            </span>
            {/* <span className="text-sm text-center ml-2 sm:text-md text-silver">
              {"Home image by Gaetano Gambino - "} gaetanogambino@ymail.com
            </span> */}
          </div>
          <div className="flex flex-row gap-5 items-center justify-center mb-2 sm:mb-0">
            <a
              href="https://www.facebook.com/unito.it"
              className="cursor-pointer hover:opacity-75"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                key="facebook"
                color="#ecebff"
                size="2xl"
              />
            </a>

            <a
              href="https://www.instagram.com/unitorino/"
              className="cursor-pointer hover:opacity-75"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagramSquare}
                key="instagram"
                color="#ecebff"
                size="2xl"
              />
            </a>

            <a
              href="https://twitter.com/unito"
              className="cursor-pointer hover:opacity-75"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faTwitterSquare}
                key="twitter"
                color="#ecebff"
                size="2xl"
              />
            </a>
          </div>
        </div>
      </div>

      {partnersSection}
    </footer>
  );
};

export default Footer;

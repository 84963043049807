import style from "./index.css";

// ICONS
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DisplayTrack = ({
  audio,
  audioRef,
  setDuration,
  progressBarRef,
  handleNext,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  return (
    <div>
      <audio
        src={process.env.REACT_APP_RESOURCES_URL + audio.url}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
      />
      <div className="flex items-center justify-center gap-5">
        <div className="w-[10vh] h-[10vh] rounded-md bg-primary-light">
          {audio.thumbnail ? (
            <span>
              {/* <img src={currentTrack.thumbnail} alt="audio avatar" /> */}{" "}
              thumbnail
            </span>
          ) : (
            <div className="icon-wrapper">
              <FontAwesomeIcon
                icon={faVolumeUp}
                color="#FFF"
                size="xl"
              />
            </div>
          )}
        </div>
        <div className="text-silver">
          <p className="text-lg font-semibold">{audio?.titoloIt}</p>
          <p className="text-sm">{audio?.autore}</p>
        </div>
      </div>
    </div>
  );
};
export default DisplayTrack;

// IMAGES
import LogoUniTo from "../../../assets/images/partners/LogoUniTo.png";
import LogoCRT from "../../../assets/images/partners/LogoFondazioneCRT.png";

import LogoME from "../../../assets/images/partners/LogoME.png";
import LogoMRT from "../../../assets/images/partners/LogoMRT.png";
import LogoMAO from "../../../assets/images/partners/LogoMAO.png";
import LogoStudiStorici from "../../../assets/images/partners/LogoStudiStorici.png";
import LogoDI from "../../../assets/images/partners/LogoDI.png";
import LogoScuolaGullini from "../../../assets/images/partners/LogoScuolaGullini.png";

export const PARTNERS_ENG = {
  founders: [
    {
      name: "University of Torino",
      image: LogoUniTo,
      link: "https://www.unito.it/ ",
      founder: true,
    },
    {
      name: "CRT Foundation",
      image: LogoCRT,
      link: "https://www.fondazionecrt.it/ ",
      founder: true,
    },
  ],
  partners: [
    {
      name: "Egyptian Museum Turin",
      image: LogoME,
      link: "https://www.museoegizio.it/",
    },
    {
      name: "Musei Reali Turin",
      image: LogoMRT,
      link: "https://museireali.beniculturali.it/ ",
    },
    {
      name: "MAO-Museum of Oriental Art Turin",
      image: LogoMAO,
      link: "https://www.maotorino.it/it",
    },
    {
      name: "Department of Historical Studies, University of Torino",
      image: LogoStudiStorici,
      link: "https://www.dipstudistorici.unito.it/do/home.pl",
    },
    {
      name: "Department of Computer Science, University of Torino",
      image: LogoDI,
      link: "http://www.di.unito.it/do/home.pl",
    },
    {
      name: "Master School of Archaeology Giorgio Gullini Turin",
      image: LogoScuolaGullini,
      link: "https://scuolaarcheologia.campusnet.unito.it/do/home.pl ",
    },
  ],
};

import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url, title, ...props }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full sm:w-[60%]">
        <ReactPlayer
          url={process.env.REACT_APP_RESOURCES_URL + url} // Replace with the URL or path to your MP4 video
          controls={true} // Enable video controls (play, pause, volume, etc.)
          width="100%" // Set the player's width
          height="auto" // Set the player's height
        />
      </div>
    </div>
  );
};

export default VideoPlayer;

import React, { useState } from "react";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Accordion = ({
  title = "",
  bgColor = "background-light",
  textColor = "primary",
  children,
  visibleHr = false,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="w-full flex flex-col items-center cursor-pointer"
    >
      <button
        className={`w-full h-[10vh] flex flex-row items-center bg-${bgColor} text-${textColor} hover:opacity-75 ${visibleHr && "px-2 sm:px-5"}`}
        onClick={toggleAccordion}
      >
        <div className="flex flex-1 items-start">
          <span className="text-lg font-semibold">{title}</span>
        </div>
        <div>
          <span>
            {!isOpen ? (
              <FontAwesomeIcon icon={faChevronDown} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} size="sm" />
            )}
          </span>
        </div>
      </button>

      {isOpen && (
        <div className="w-full">
          {visibleHr && <hr className="w-full text-primary-light" />}
          <div className="cursor-default">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Accordion;


// IMPORTS
import { NAVLINKS_ENG } from "./navLinks";
import { PROJECTPAGE_ENG } from "./projectPage";
import { UNITSPAGE_ENG } from "./unitsPage";
import { COLLECTIONSPAGE_ENG } from "./collectionsPage";
import { HOMEPAGE_ENG } from "./homePage";
import { MUSEUMSPAGE_ENG } from "./museumsPage";
import {PARTNERS_ENG} from "./partners"
import { PARTNERSPAGE_ENG } from "./partnersPage";

export const ENG_DICTIONARY = {
    language: "GB",
    title: "Afterlife",
    subtitle: "The unsaid of the materiality of the past",
    links: NAVLINKS_ENG,
    homePage: HOMEPAGE_ENG,
    projectPage: PROJECTPAGE_ENG,
    museumsPage: MUSEUMSPAGE_ENG,
    collectionsPage: COLLECTIONSPAGE_ENG,
    unitsPage: UNITSPAGE_ENG,
    partnersPage: PARTNERSPAGE_ENG,
    oltrevitaPageCollection: {
      breadcrumbs: [
        { pageName: "Home", path: "/", active: false },
        { pageName: "The collections", path: "/Reperti/Collezioni", active: false },
        { pageName: "Oltrevita", path: "/Reperti/Collezioni/Oltrevita/", active: false },
      ],
    },
    oltrevitaPageUnit: {
      breadcrumbs: [
        { pageName: "Home", path: "/", active: false },
        { pageName: "The Objects", path: "/Reperti/Opere", active: false },
        { pageName: "Afterlife", path: "/Reperti/Opere/Oltrevita/", active: false },
      ],
    },
    labels: {
      scopri: "Discover more",
      ulterioriInfo: "More Informations",
      socialTitle: "Follow us on socials:",
    },
  partners: PARTNERS_ENG,
  footer: {
    aboutUs: "About us",
    aboutUsText: "Oltrevita is committed to enhancing ancient objects, symbols of our contemporaneity, to reveal their hidden meanings, stories and potential.",
    contactUs: "Contacts",
    contactUsText: {
      t1: "Address: ",
      t2: "Phone: ",
      t3: "Email: "
    }
  }
  };
import React from "react";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft, faChevronRight
} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ currentPage = 1, totPages, onPage = () => {} }) => {

  const boxClass = "flex items-center justify-center h-[3vh] w-[3vh] p-5 cursor-pointer bg-background-light border-2 border-primary-light text-primary-light hover:bg-primary-light hover:text-background-light";  

  // Returns an array wich contains the available previous pages
  const previousPages =
    currentPage === 1
      ? []
      : currentPage === 2
      ? [1]
      : currentPage === 3
      ? [1, 2]
      : [currentPage - 2, currentPage - 1];

  // Returns an array wich contains the available next pages
  const nextPages =
    currentPage === totPages
      ? []
      : currentPage === totPages - 1
      ? [totPages]
      : currentPage === totPages - 2
      ? [totPages - 1, totPages]
      : [currentPage + 1, currentPage + 2, currentPage + 3];

  const previousPagesComponent = previousPages.map((page) => (
    <div
      key={page}
      onClick={() => onPage(page)}
      className={boxClass}
    >
      {page}
    </div>
  ));

  const nextPagesComponent = nextPages.map((page) => (
    <div
      key={page}
      onClick={() => onPage(page)}
      className={boxClass}
    >
      {page}
    </div>
  ));

  return (
    <div className="w-full bg-background h-[6vh] rounded-b-md flex justify-center sm:justify-end">
      <div className="w-full sm:w-[50%] lg:w-[30%] flex items-center justify-center gap-10 py-10">
        <div
          onClick={() => onPage(1)}
          className={boxClass}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="flex flex-row gap-3">
          {currentPage > 3 && <div className="p-1 px-3 rounded-sm">...</div>}
          {previousPagesComponent}
          <div className="flex items-center justify-center h-[3vh] w-[3vh] p-5 cursor-pointer border-2 border-primary-light bg-primary-light text-background-light">{currentPage}</div>
          {nextPagesComponent}
          {currentPage < totPages - 3 && (
            <div className="p-1 px-3 rounded-sm">...</div>
          )}
        </div>
        <div
          onClick={() => onPage(totPages)}
          className={boxClass}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;

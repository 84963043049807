export const HOMEPAGE_ITA = {
  labelProgetto: "Oltrevita",
  descrizioneProgetto:
    "intende sviluppare nuove forme narrative che raccontano il presente attraverso oggetti del passato. Questi oggetti non hanno infatti interagito soltanto con chi, un tempo, li ha prodotti e utilizzati; essi interagiscono anche con noi, oggi, tanto da essere percepiti in alcuni casi come simboli della contemporaneità.",
  callToAction: "Scopri i reperti",
  museumsSection: {
    title: "I Musei",
  },
  findsSection: {
    title: "I Reperti",
    description:
      "Navigando nel portale o inquadrando i codici QR posizionati nei musei, vicino a reperti selezionati, potrai vedere, ascoltare, leggere storie del nostro tempo, che vedono ancora protagonisti gli oggetti del passato.",
    cards: {
      collections: { title: "Le Collezioni" },
      works: { title: "Le Opere" },
    },
  },
};

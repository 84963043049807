import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const collezioneApi = {

  getCollections: async (filters, numberOfPage, itemsPerPage) => {
    try {
      let request = `${url}/collezione-api.php?numberOfPage=` + numberOfPage + `&itemsPerPage=` + itemsPerPage;

        if(filters?.collectionName) request += `&name=` + filters.collectionName;
        if(filters?.museum?.id) request += `&museumId=` + filters.museum?.id;

      const response = await axios.get(request);
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  getCollection: async (id) => {
    try {
      const response = await axios.get(
        `${url}/collezione-api.php?ID=${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  createCollection: async (data) => {
    axios
      .post(url + "/collezione-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  updateCollection: async (data) => {
    axios
      .post(url + "/collezione-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  deleteCollection: async (data) => {
    axios
      .post(url + "/collezione-api.php", data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

export default collezioneApi;

import { TEAM_ITA } from "./team";

export const PROJECTPAGE_ITA = {
  title: "Progetto",
  breadcrumbs: [
    { pageName: "Home", path: "/", active: false },
    { pageName: "Il progetto", path: "/Progetto", active: true },
  ],
  team: TEAM_ITA,
  progettiCorrelatiTitle: "Progetti correlati"
};

import React from "react";

const Button = ({
  onClick = () => {},
  disabled = false,
  variant = 1,
  classes,
  children,
  ...props
}) => {
  return (
    <button
      onClick={(event) => {
        event.stopPropagation();
        onClick(event);
      }}
      disabled={disabled}
      className={`px-5 py-2 sm:py-3 rounded-lg shadow-md" 
      ${
        variant === 1 &&
        "bg-secondary-dark text-silver transition ease-in-out delay-100 hover:bg-secondary"
      }
      ${
        variant === 2 &&
        "bg-secondary text-silver transition ease-in-out delay-100  hover:bg-secondary-dark"
      }
      ${
        variant === 3 &&
        "bg-transparent border-2 border-secondary text-secondary transition ease-in-out delay-100 hover:bg-secondary hover:text-silver"
      } 
      ${classes}
      `}
    >
      {children}
    </button>
  );
};

export default Button;

import React from "react";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Input = ({
  componentRef = null,
  type = "text",
  placeholder,
  icon,
  name,
  value,
  onChange = () => {},
  variant,
  label,
  autofocus = false,
  onBlur = () => {},
  isSearch = false,
  onSearch = () => {},
  isValid = null,
  isInvalid = null,
  disabled = false,
  classes,
  darkMode = false,
  ...props
}) => {
  const input = (
    <div
      className={`w-full h-[6vh] sm:h-[5vh] flex items-center px-3 ${
        isSearch ? "rounded-l-md rounded-r-none" : "rounded-md"
      }
       ${isValid && "border-green"} ${isInvalid && "border-red"} ${
        disabled
          ? "cursor-not-allowed bg-gray"
          : darkMode
          ? "bg-[#434343]"
          : "bg-white border-2 border-primary-light"
      }`}
    >
      {icon}
      <input
        ref={componentRef}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onBlur={(event) => onBlur(event.target.value)}
        autoFocus={autofocus}
        disabled={disabled}
        className={`outline-none w-full ${
          disabled
            ? "cursor-not-allowed bg-gray"
            : darkMode
            ? "bg-[#434343] text-silver"
            : "bg-white"
        }`}
        {...props}
      />
    </div>
  );

  return (
    <div className={classes}>
      {label && (
        <span
          className={`${isInvalid && "text-red"} ${darkMode && "text-silver"} `}
        >
          {label}
        </span>
      )}
      <div className="flex">
        {input}
        {isSearch && (
          <div 
            className="flex items-center justify-center w-[6vh] h-[6vh] sm:h-[5vh] rounded-l-none rounded-r-md bg-secondary text-primary-light hover:bg-secondary-dark hover:text-silver"
            onClick={onSearch}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;

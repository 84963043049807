import React, { useState, useEffect } from "react";
import ItemsCarousel from "react-items-carousel";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const Carousel = ({
  noOfCards = 4,
  autoPlay = true,
  gutter = 20,
  hideArrows = false,
  autoPlayDelay = 3000,
  chevronWidth = 40,
  leftChevron = (
    <div className="text-silver hover:opacity-75">
      <FontAwesomeIcon icon={faChevronCircleLeft} color="#ecebff" size="xl" />
    </div>
  ),
  rightChevron = (
    <div className="text-silver hover:opacity-75">
      <FontAwesomeIcon icon={faChevronCircleRight} size="xl" />
    </div>
  ),
  children,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const tick = () => {
    setActiveItemIndex(activeItemIndex + 1);
  };

  useEffect(() => {
    if(autoPlay){
      let interval = setInterval(tick, autoPlayDelay);

    return () => {
      clearInterval(interval);
    };
    }
  });

  const onChangeItem = (value) => {
    setActiveItemIndex(value);
  };

  return (
    <div style={{ padding: `0 ${!hideArrows ? chevronWidth : 0}px` }}>
      <ItemsCarousel
        activeItemIndex={activeItemIndex}
        requestToChangeActive={onChangeItem}
        infiniteLoop={true}
        numberOfCards={isMobile ? 1 : noOfCards}
        gutter={gutter}
        leftChevron={!hideArrows ? leftChevron : null}
        rightChevron={!hideArrows ? rightChevron : null}
        outsideChevron
        chevronWidth={chevronWidth}
        children={children}
      />
    </div>
  );
};

export default Carousel;

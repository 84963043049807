import React from "react";

import imagePlaceholder from "../../assets/images/userPlaceholder.jpeg";

const TeamMember = ({
  image = imagePlaceholder,
  fullName,
  role,
  foundation,
  linkUrl,
  myKey,
  ...props
}) => {
  return (
    <a href={linkUrl} className="w-[32vh] sm:w-[31vh] h-auto flex flex-col items-center justify-center cursor-pointer hover:opacity-75"  target='_blank' rel='noreferrer' key={myKey} >
      <div
        className="w-[20vh] h-[20vh] rounded-full"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
        }}
      />
      <span className="text-white font-semibold text-center text-xl mt-3">{fullName}</span>
      <span className="text-white text-center italic mt-2 px-5">
        {foundation}
      </span>
      <span className="text-white font-semibold text-center mt-2">{role}</span>
    </a>
  );
};

export default TeamMember;

// Api

import { museoApi } from "../../api";

const initialState = {
  museums: [],
  museum: null,
  totalMuseums: null
};

const museumsStore = (set, get) => ({
  ...initialState,

  getMuseums: async (filters) => {
    const res = await museoApi.getMuseums(filters);
    if (res && res.status === 200) {

      set({
        museums: res.data ? res.data?.items : [],
        totalMuseums: res.data ? res.data.totalItems : null
      });
      return { value: true };
    } else return false;
  },

  getMuseum: async (id) => {
    const res = await museoApi.getMuseum(id);
    if (res && res.status === 200) {
      set({
        museum: res.data ? res.data?.items[0] : null,
      });
      return { value: true };
    } else return false;
  },

  createMuseum: async (data) => {
    const res = await museoApi.createMuseum(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },

  updateMuseum: async (data) => {
    const res = await museoApi.updateMuseum(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },

  deleteMuseum: async (data) => {
    const res = await museoApi.deleteMuseum(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },

});

export default museumsStore;

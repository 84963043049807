import React, { useState, useEffect } from "react";

import { useStore } from "../../store/stores/store";

// Components
import Pagetitle from "../../components/Pagetitle.component";
import Breadcrumb from "../../components/Breadcrumb.component";
import Image from "../../assets/images/scavi-el-medina.jpg";
import Accordion from "../../components/Accordion.component";
import Input from "../../components/Input.component";
import LargeCard from "../../components/LargeCard.component";
import Pagination from "../../components/Pagination.components";
import PageLoading from "../../components/PageLoading.component";

const CollectionsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [itemsTitle, setItemsTitle] = useState("");
  const itemsPerPage = 9;
  const [numberOfPage, setNumberOfPage] = useState(1);

  const [filters, setFilters] = useState({
    collectionName: "",
    museum: "",
  });

  const dictionary = useStore((x) => x.dictionary);
  const getCollections = useStore((x) => x.getCollections);
  const collections = useStore((x) => x.collections);
  const getMuseums = useStore((x) => x.getMuseums);
  const museums = useStore((x) => x.museums);
  const totItems = useStore((x) => x.totalCollections);
  const activeLang = useStore((x) => x.activeLang?.value);

  const setTitle = (filterList) => {
    let title =
      filterList?.collectionName !== ""
        ? activeLang === "IT"
          ? "Risultati per " + filterList?.collectionName
          : "Results for " + filterList?.collectionName
        : activeLang === "IT"
        ? "Tutte le collezioni"
        : "All collections";

    title += filterList?.museum && " - " + filterList?.museum?.name;

    setItemsTitle(title);
  };

  async function getData(filters, numberOfPage, itemsPerPage) {
    await getCollections(filters, numberOfPage, itemsPerPage);
    await getMuseums();
    setTitle({ ...filters });
  }

  useEffect(() => {
    document.title = "Oltrevita - Le collezioni";

    setIsLoading(true);
    getData(filters, numberOfPage, itemsPerPage);

    const interval = setInterval(() => {
      setIsLoading(false);
      clearInterval(interval);
    }, 1000);
  }, []);

  const handleFilter = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  async function handleSelect(field, value) {
    setIsLoading(true);
    handleFilter(field, value);
    getCollections({ ...filters, [field]: value }, numberOfPage, itemsPerPage);
    setTitle({ ...filters, [field]: value });
    setIsLoading(false);
  }

  const handleKeySubmit = (event) => {
    if (event.key === "Enter") {
      setIsLoading(true);
      getCollections(filters, numberOfPage, itemsPerPage);
      setTitle({ ...filters });
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    getCollections(filters, numberOfPage, itemsPerPage);
    setTitle({ ...filters });
    setIsLoading(false);
  };

  const handlePageChange = (nPage) => {
    setNumberOfPage(nPage);
    getData(filters, nPage, itemsPerPage);
  };

  const getMuseumName = (id) => {
    switch(id){
      case 'Y6HCLU':
        return "Museo di Antichità (dei Musei Reali)";
      case '9KI4AT':
        return "Museo Egizio di Torino"; 
      case 'M8TM8G':
        return "Museo d'Arte Orientale";
      default :
      return "Museo";
    }
  }

  const collectionsListSection = (
    <div className="flex flex-col sm:flex-row items-center sm:items-start w-full bg-background-light py-10 sm:py-5">
      <div className="flex flex-col w-[90%] lg:w-[20%] sm:w-[30%] gap-3 px-5 sm:px-7">
        <div className="flex h-[10vh] justify-center items-center text-primary">
          <span className="text-xl font-semibold pb-3 block sm:hidden">
            {itemsTitle}
          </span>
        </div>
        <Accordion title={activeLang === "IT" ? "Cerca" : "Search"}>
          <div className="w-full">
            <Input
              placeholder="Nome della collezione"
              onChange={(value) => handleFilter("collectionName", value)}
              isSearch
              onSearch={handleSubmit}
              onKeyPress={handleKeySubmit}
              darkMode
            />
          </div>
        </Accordion>

        {museums && museums.length > 0 && (
          <>
            <hr className="text-primary-light" />
            <Accordion
              title={
                activeLang === "IT" ? "Filtra per museo" : "Filter by museum"
              }
            >
              <div className="flex flex-col gap-3 w-full font-semibold">
                <span
                  className={`cursor-pointer hover:opacity-75 ${
                    filters.museum === "" && "underline"
                  } `}
                  onClick={() => handleSelect("museum", "")}
                >
                  {activeLang === "IT" ? "Tutti i musei" : "All museums"}
                </span>
                {museums.map((museum) => (
                  <span
                    key={museum.ID}
                    className={`cursor-pointer hover:opacity-75 ${
                      museum?.ID === filters.museum?.id && "underline"
                    } `}
                    onClick={() =>
                      handleSelect("museum", {
                        name: museum?.nome,
                        id: museum?.ID,
                      })
                    }
                  >
                    {museum?.nome}
                  </span>
                ))}
              </div>
            </Accordion>
          </>
        )}
      </div>

      <div className="flex flex-col w-full lg:w-[80%] sm:w-[70%] p-5">
        <div className="h-[10vh] hidden sm:flex sm:items-center">
          <span className="text-xl font-semibold pb-3 ">{itemsTitle}</span>
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap gap-5">
          {collections && collections.length > 0 ? (
            <>
              {collections.map((x) => (
                <LargeCard
                  title={activeLang === "IT" ? x.nomeIt : x.nomeEn}
                  subtitle={getMuseumName(x.ID_museo)}
                  image={process.env.REACT_APP_COLLECTIONS_IMAGES_URL + x?.img}
                  key={x.ID}
                  link={`/Oltrevita/Collezione/${x.ID}`}
                />
              ))}
              <div className="w-full items-end">
                <Pagination
                  totPages={Math.ceil(totItems / 9)}
                  currentPage={numberOfPage}
                  onPage={() => console.log("Ciao")}
                />
              </div>
            </>
          ) : (
            <div className="w-full h-full min-h-[30vh] flex justify-center items-center">
              <span className="text-primary">
                Non sono state trovate collezioni
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={dictionary?.collectionsPage?.breadcrumbs} />
      <div className="flex flex-col w-full items-center">
        <Pagetitle
          title={dictionary?.collectionsPage?.title}
          description={dictionary?.collectionsPage?.description}
        />
        {collectionsListSection}

        <PageLoading isLoading={isLoading} />
      </div>
    </>
  );
};

export default CollectionsPage;

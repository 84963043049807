export const NAVLINKS_ENG = [
  { pageName: "Homepage", path: "/" },
  { pageName: "Project", path: "/Progetto" },
  { pageName: "Museums", path: "/Musei" },
  {
    pageName: "Artifacts",
    path: "/Reperti",
    isDropdown: true,
    options: [
      { path: "/Reperti/Collezioni", pageName: "Collections" },
      { path: "/Reperti/Opere", pageName: "Works" },
    ],
  },
  { pageName: "Partners", path: "/Partners" },
];

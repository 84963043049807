import React from "react";

const Pagetitle = ({
    title,
    description
}) => {

  return (
    <div className="w-full lg:w-[60%] sm:w-[80%] py-10 px-5 bg-primary">

      <div className="flex items-center gap-10 pb-5">
        <h1 className="text-silver text-2xl">{title}</h1>
        <hr className="w-full text-primary-light" />
      </div>

      <span className="text-lg text-silver">
        {description}
      </span>
    </div>
  );
};

export default Pagetitle;

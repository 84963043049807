// Api
import { collezioneApi, risorsaApi } from "../../api";

const initialState = {
  collections: [],
  collection: null,
  collectionResources: [],
  breadcrumbs: [
    { pageName: "Home", path: "/", active: false },
    { pageName: "Le collezioni", path: "/Reperti/Collezioni", active: false },
    {
      pageName: "Oltrevita",
      path: "/Reperti/Collezioni/Oltrevita/",
      active: false,
    },
  ],
  totalCollections: null,
};

const collectionsStore = (set, get) => ({
  ...initialState,

  getCollections: async (filters, numberOfPage, itemsPerPage) => {
    const res = await collezioneApi.getCollections(
      filters,
      numberOfPage,
      itemsPerPage
    );
    if (res && res.status === 200) {
      set({
        collections: res.data ? res.data?.items : [],
        totalCollections: res.data ? res.data.totalItems : null,
      });
      return { value: true };
    } else return false;
  },

  getCollection: async (id) => {
    const res = await collezioneApi.getCollection(id);

    if (res && res.status === 200) {
      set({
        collection: res.data ? res.data?.collezione : null,
        collectionResources: res.data ? res.data?.risorse : null,
        breadcrumbs:
          (res.data?.collezione?.nomeIt || res.data?.collezione?.nomeEn) &&
          get().dictionary?.oltrevitaPageCollection?.breadcrumbs.map((x) =>
            x.pageName === "Oltrevita"
              ? {
                  pageName:
                    get().activeLang.value === "IT"
                      ? "Oltrevita " + res.data?.collezione?.nomeIt
                      : "Afterlife " + res.data?.collezione?.nomeEn,
                  path: `Reperti/Collezioni/Oltrevita/${res.data?.collezione.ID}`,
                  active: true,
                }
              : x
          ),
      });

      if (res.data?.risorse && res.data?.risorse.length > 0) {
        let risorseToDisplay = [];

        for (const risorsa of res.data?.risorse) {
          switch (risorsa.tipologia) {
            //Gestione tipologie di media risorsa
            case "fumetto":
              let fumettoRes = await risorsaApi.getResource(risorsa.ID);

              // Aggiungo la risorsa con la lista 'fumetto' contenente le pagine del fumetto
              risorseToDisplay.push(
                fumettoRes.data.images
                  ? { ...risorsa, fumetto: fumettoRes.data.images }
                  : risorsa
              );

              break;

            case "galleria":
              let galleriaRes = await risorsaApi.getResource(risorsa.ID);

              // Aggiungo la risorsa con la lista 'galleria' contenente le pagine del fumetto
              risorseToDisplay.push(
                galleriaRes.data.images
                  ? { ...risorsa, galleria: galleriaRes.data.images }
                  : risorsa
              );

              break;

            case "documento":
              risorseToDisplay.push({ ...risorsa });
              break;
            case "audio":
              risorseToDisplay.push({ ...risorsa });
              break;
            case "video":
              risorseToDisplay.push({ ...risorsa });
              break;

            default:
              console.log("Tipologia non riconosciuta");
          }
        }

        set({
          collectionResources: risorseToDisplay,
        });
      }
    }
  },

  createCollection: async (data) => {
    const res = await collezioneApi.createCollection(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },

  updateCollection: async (data) => {
    const res = await collezioneApi.updateCollection(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },

  deleteCollection: async (data) => {
    const res = await collezioneApi.deleteCollection(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },
});

export default collectionsStore;

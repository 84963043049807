import React from "react";
import HashLoader from "react-spinners/HashLoader";


const PageLoading = ({isLoading = false}) => {

  document.body.style.overflow = isLoading ? 'hidden' : 'auto';

  return (
    <>
    {isLoading && 
      <div className="absolute w-full h-full top-0 left-0 z-[999] flex justify-center items-center bg-[RGBA(32,32,32,0.95)]">
        <HashLoader color="#8B8000" size={70} />
      </div>
    }
    </>
  );
};

export default PageLoading;

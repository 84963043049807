import React, { useState, useEffect } from "react";

// STORE
import { useStore } from "../store/stores/store";

// COMPONENTS
import Pagetitle from "../components/Pagetitle.component";
import Breadcrumb from "../components/Breadcrumb.component";
import Input from "../components/Input.component";
import MuseoCard from "../components/Homepage/MuseoCard.component";
import Accordion from "../components/Accordion.component";

// IMAGES
import Pagination from "../components/Pagination.components";
import PageLoading from "../components/PageLoading.component";

const MuseumsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [itemsTitle, setItemsTitle] = useState("");

  const [filters, setFilters] = useState({
    museumName: "",
  });

  const dictionary = useStore((x) => x.dictionary);
  const getMuseums = useStore((x) => x.getMuseums);
  const museums = useStore((x) => x.museums);
  const totItems = useStore((x) => x.totalMuseums);
  const activeLang = useStore((x) => x.activeLang.value);

  const setTitle = () => {
    setItemsTitle(
      filters.museumName !== ""
        ? activeLang === "IT" ? "Risultati per " + filters.museumName : "Result for " + filters.museumName
        : activeLang === "IT" ? "Tutti i musei" : "All the museums"
    );
  };

  async function getData() {
    await getMuseums();
  }

  useEffect(() => {
    document.title = "Oltrevita - I musei";

    setIsLoading(true); 

    getData();
    setTitle();

    const interval = setInterval(() => {      
      setIsLoading(false);
      clearInterval(interval);
    }, 1000);

  }, []);

  const handleFilter = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleKeySubmit = (event) => {
    if(event.key === 'Enter'){
      getMuseums(filters);

    setTitle();
    }
  }

  const handleSubmit = () => {
    getMuseums(filters);

    setTitle();
  };

  const museumsSection = (
    <div className="flex flex-col sm:flex-row items-center w-full bg-background-light py-10 sm:py-5">
      <div className="flex flex-col w-[90%] sm:w-[20%] sm:h-[20vh] gap-3 px-5 sm:px-7">
        <div className="flex h-[10vh] justify-center items-center text-primary sm:hidden">
          <span className="text-xl font-semibold pb-3 block sm:hidden">
            {itemsTitle}
          </span>
        </div>
        <Accordion title="Cerca">
          <div className="w-full">
            <Input
              placeholder="Cerca per nome del museo"
              onChange={(value) => handleFilter("museumName", value)}
              isSearch
              onKeyPress={handleKeySubmit}
              onSearch={handleSubmit}
              darkMode
            />
          </div>
        </Accordion>
      </div>

      <div className="flex flex-col w-full sm:w-[80%] p-5">
        <div className="h-[10vh] hidden sm:flex sm:items-center">
          <span className="text-xl font-semibold pb-3 ">{itemsTitle}</span>
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap gap-5">
          {museums && museums.length > 0 ? (
                <>
                  {museums.map((x) => (
                    <MuseoCard
                      title={x.nome}
                      //subtitle="Scopri tutti i musei del progetto Oltrevita"
                      image={process.env.REACT_APP_MUSEUMS_IMAGES_URL + x.image}
                      key={x.ID}
                      link={x.url}
                    />
                  ))}

                  {/* <div className="w-full sm:items-end">
                    <Pagination
                      totPages={Math.ceil(totItems/9)}
                      onPage={() => console.log("Cambio pagina")}
                    />
                  </div> */}
                </>
          ) : (
            <div className="w-full h-full min-h-[30vh] flex justify-center items-center">
              <span className="text-primary">Non sono stati trovati musei</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={dictionary?.museumsPage?.breadcrumbs} />

      <div className="flex flex-col w-full items-center">
        <Pagetitle
          title={dictionary.museumsPage?.title}
          description={dictionary.museumsPage?.description}
        />
        {museumsSection}

        <PageLoading isLoading={isLoading} />
      </div>
    </>
  );
};

export default MuseumsPage;

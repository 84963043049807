
// IMPORTS
import { NAVLINKS_ITA } from "./navLinks";
import { HOMEPAGE_ITA } from "./homePage";
import { PROJECTPAGE_ITA } from "./projectPage";
import { MUSEUMSPAGE_ITA } from "./museumsPage";
import { COLLECTIONSPAGE_ITA } from "./collectionsPage";
import { UNITSPAGE_ITA } from "./unitsPage";
import { PARTNERS_ITA } from "./partners";
import { PARTNERSPAGE_ITA } from "./partnersPage";

export const ITA_DICTIONARY = {
  language: "IT",
  title: "Oltrevita",
  subtitle: "Il non detto della materialità del passato",
  links: NAVLINKS_ITA,
  homePage: HOMEPAGE_ITA,
  projectPage: PROJECTPAGE_ITA,
  museumsPage: MUSEUMSPAGE_ITA,
  collectionsPage: COLLECTIONSPAGE_ITA,
  unitsPage: UNITSPAGE_ITA,
  partnersPage: PARTNERSPAGE_ITA,
  oltrevitaPageCollection: {
    breadcrumbs: [
      { pageName: "Home", path: "/", active: false },
      { pageName: "Le collezioni", path: "/Reperti/Collezioni", active: false },
      { pageName: "Oltrevita", path: "/Reperti/Collezioni/Oltrevita/", active: false },
    ],
  },
  oltrevitaPageUnit: {
    breadcrumbs: [
      { pageName: "Home", path: "/", active: false },
      { pageName: "Le Opere", path: "/Reperti/Opere", active: false },
      { pageName: "Oltrevita", path: "/Reperti/Opere/Oltrevita/", active: false },
    ],
  },
  labels: {
    scopri: "Scopri di più",
    ulterioriInfo: "Ulteriori Informazioni",
    socialTitle: "Seguici sui social:",
  },
  partners: PARTNERS_ITA,
  footer: {
    aboutUs: "Riguardo noi",
    aboutUsText: "Oltrevita si impegna a valorizzare gli oggetti antichi, simboli della nostra contemporaneità, per rivelarne significati, storie e potenzialità nascoste.",
    contactUs: "Contatti",
    contactUsText: {
      t1: "Indirizzo: ",
      t2: "Telefono: ",
      t3:  "Email: "
    }
  }
};

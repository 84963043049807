import React from "react";
import { useNavigate } from "react-router-dom";

const LargeCard = ({
  onClick = () => {},
  disabled = false,
  variant,
  classes,
  children,
  title,
  subtitle,
  label,
  link,
  image,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <button
      className="h-[400px] w-full lg:w-[400px] lg:h-[400px] md:h-[225px] md:w-[225px] flex flex-col bg-primary shadow-xl sm:hover:opacity-60 cursor-pointer rounded-sm"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `100% 100%`,
        backgroundRepeat: `no-repeat`,
      }}
      onClick={() => navigate(link)}
      aria-label={label}
    >
      <div className="flex w-full h-full bg-gradient-to-b from-transparent via-transparent to-primary justify-center items-end px-3">
        <div className="flex flex-col justify-center items-center">
          <span className="text-silver text-2xl pb-3">{title}</span>
          <span className="text-silver pb-10">{subtitle}</span>
        </div>
      </div>
    </button>
  );
};

export default LargeCard;

import React from "react";

const LogoPartner = ({image, alt, link}) => {
  const imageContainerClass =
    "flex w-[50%] sm:w-[30%] h-[100px] items-center justify-center";
  const imageClass =
    "w-[150px] h-[50px] cursor-pointer hover:w-[180px] hover:h-[60px]";

  return (
    <a href={link} className={imageContainerClass}  target='_blank' rel='noreferrer'>
      <img src={image} alt={alt} className={imageClass} />
    </a>
  );
};

export default LogoPartner;

import { DICTIONARY } from "../../dictionary/dictionary";

import detectBrowserLanguage from 'detect-browser-language'

const initialState = {
  activeLang: detectBrowserLanguage().split("-")[0] === "it" ? { label: "Italiano", value: "IT" } : {label: "English", value: "GB"},
  dictionary: {},
};

const languageStore = (set, get) => ({
  ...initialState,

  setLanguage: function (lang) {
    set({
      activeLang: { label: lang.label, value: lang.value },
    });

    set({
      dictionary: DICTIONARY.find((x) => x.language === get().activeLang.value),
    });
  },

  setDictionary: function () {
    set({
      dictionary: DICTIONARY.find((x) => x.language === get().activeLang.value),
    });
  },
});

export default languageStore;

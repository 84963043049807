export const NAVLINKS_ITA = [
  { pageName: "Homepage", path: "/" },
  { pageName: "Il progetto", path: "/Progetto" },
  { pageName: "I musei", path: "/Musei" },
  {
    pageName: "I reperti",
    path: "/Reperti",
    isDropdown: true,
    options: [
      { path: "/Reperti/Collezioni", pageName: "Le collezioni" },
      { path: "/Reperti/Opere", pageName: "Le opere" },
    ],
  },
  { pageName: "I partners", path: "/Partners" },
];

import React, { useEffect } from "react";

// STORE
import { useStore } from "../store/stores/store";

// ICONS
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// COMPONENTS
import TeamMember from "../components/Project/TeamMember.component";
import Carousel from "../components/ItemCarousel.component";
import Breadcrumb from "../components/Breadcrumb.component";
import Accordion from "../components/Accordion.component";
import Pagetitle from "../components/Pagetitle.component";

// IMAGES
import userPlaceholder from "../assets/images/userPlaceholder.jpeg";

// Images Related Projects
import Logo1 from "../assets/images/relatedProjects/LogoArCOA.png";
import Logo2 from "../assets/images/relatedProjects/LogoiCommunity.png";
import Logo3 from "../assets/images/relatedProjects/LOGOINVCOLL.png";

// FILES
import pdfFile from "../assets/file/2021-bando-ordinarie.pdf";

const Project = () => {
  useEffect(() => {
    document.title = "Oltrevita - Il progetto";
  }, []);

  const dictionary = useStore((x) => x.dictionary);

  const pClass = "text-lg text-silver";

  const accordionsSection = (
    <div className="flex flex-col w-full lg:w-[60%] sm:w-[80%] items-center pb-10 px-5">
      <div className="flex flex-col w-full sm:w-[90%] gap-3 sm:gap-5">
        <Accordion
          title={dictionary.language === "IT" ? "Panoramica" : "Overview"}
          bgColor="primary"
          textColor="silver"
          key="Panoramica"
        >
          <div className="flex flex-col text-start">
            <span className={pClass + " mb-3"}>
              {dictionary.language === "IT"
                ? "Nella percezione comune, un reperto archeologico racconta il passato. Questa percezione è fuorviante. Lo studio dei materiali antichi fornisce un indiscutibile contributo alla comprensione del passato, ma non ne esaurisce la potenzialità informativa e la capacità agente. La produzione, l’uso e l’abbandono di un manufatto costituiscono infatti solo una fase della sua temporalità (una temporalità molto più duratura dell’esistenza in vita di chi lo ha realizzato e utilizzato). Questa fase è definita pre-deposizionale in archeologia."
                : "In the common opinion, antiquities tell the past. Such an opinion is misleading. The study of ancient objects enhances our understanding of antiquity, but the potential information embodied in materiality as well as its agency are not exploited completely. The making and use of an ancient object overlap only the initial phase of its temporality, a temporality usually far longer than makers’ and users’ lifetime. In archaeology, this is called pre-depositional phase."}
            </span>

            <span className={pClass + " mb-3"}>
              {dictionary.language === "IT"
                ? "Dopo l’abbandono, i manufatti antichi rimangono in un lungo periodo d’inerzia interattiva, corrispondente alla loro permanenza in un contesto deposizionale (ad esempio nel terreno) fino alla loro (eventuale) riscoperta. Inizia allora una nuova fase d’interazione con l’ambiente umano, definibile come fase post-deposizionale. Si tratta a tutti gli effetti di un’oltrevita, durante la quale i manufatti antichi sono nuovamente agenti in dinamiche complesse."
                : "After its abandonment, an ancient object enters a lasting period of inertness, corresponding to its permanence in a depositional context (such as the terrain), until it is (eventually) rediscovered. Once rediscovered, ancient objects are re-entangled in the human environment. In archaeology, this is called post-depositional phase, indeed an afterlife in which artifacts have agency on human beings anew."}
            </span>

            <span className={pClass + " mb-3"}>
              {dictionary.language === "IT"
                ? "Se la fase pre-deposizionale è protagonista delle narrazioni sui manufatti antichi, sia nei resoconti scientifici, sia in letteratura, sia nel mainstream mediatico, la fase post-deposizionale, l’oltrevita, è enormemente trascurata. Nell’oltrevita i materiali antichi tornano a essere potenti latori di significato, recuperando la loro capacità evocativa per farsi tramite delle vicende di chi li ha riscoperti, li ha storicizzati, ne è divenuto fruitore o li ha utilizzati per fini differenti. Spesso essi sono anzi divenuti simboli iconici di una nuova contemporaneità (come, ad esempio, i bronzi di Riace, simbolo oggi universalmente noto della Regione Calabria)."
                : "The artifacts’ pre-depositional phase is widely addressed by scientific literature and by the media mainstream. The post-depositional phase –the objects’ afterlife– is instead largely neglected. During their afterlife, ancient objects are once again meaning bearers, as, along with the story of past people, they transmit the story of contemporaneous people, namely those who rediscovered, historicized, and used (or abused) them. Sometimes, ancient objects become iconic symbols of a new contemporaneity (such as roman military insignia, symbols of modern totalitarian regimes)."}
            </span>

            <span className={pClass}>
              {dictionary.language === "IT"
                ? "Questo progetto mira a intercettare e ritrasmettere l’oltrevita di alcuni oggetti del passato entrati a far parte del nostro patrimonio durante la fase post-deposizionale per raccontare, attraverso essi, il nostro presente."
                : "This project aims to intercept and retransmit the afterlife of selected antiquities that are now part of the Common Heritage to tell via them stories of our times."}
            </span>
          </div>
        </Accordion>

        <hr className="w-full text-primary-light" />

        <Accordion
          title={dictionary.language === "IT" ? "Obiettivi" : "Outrech"}
          bgColor="primary"
          textColor="silver"
          key="Obiettivi"
        >
          <div className="flex flex-col text-start">
            <span className={pClass + " mb-3"}>
              {dictionary.language === "IT"
                ? "Il progetto segue una chiara impostazione di community-engagement e si rivolge a varie tipologie di pubblico. L’accesso al patrimonio culturale per il più ampio numero possibile di fruitori è una delle priorità delle politiche di promozione culturale dell’Unione Europea sulla base della Convenzione di Faro."
                : "This is a community-engagement project, targeting different types of audience. The access to the Common Heritage for the widest possible audience is a priority for the cultural policies of the EU Members based on the Faro Convention."}
            </span>

            <span className={pClass}>
              {dictionary.language === "IT"
                ? "La creazione di sinergie e reti interdisciplinari di ricercatori e futuri professionisti della valorizzazione, promozione e tutela dei Beni Culturali rientra nelle linee d’indirizzo strategiche di Istituzioni scientifiche e culturali, anche in termini di sostenibilità."
                : "The building of cross-disciplinary networks and the promotion of the Common Heritage is a strategic task of scientific and cultural Institutions in terms of sustainability."}
            </span>
          </div>
        </Accordion>

        <hr className="w-full text-primary-light" />

        <Accordion
          title={
            dictionary.language === "IT"
              ? "Amministrazione e offerta"
              : "Administration and tenders"
          }
          bgColor="primary"
          textColor="silver"
          key="Amministrazione e offerta"
        >
          <div className="flex flex-col text-start">
            <span className={pClass + " mb-3"}>
              <span>
                {dictionary.language === "IT"
                  ? "Le pratiche amministrative sono gestite dall’Università di Torino,"
                  : "Administration procedures are managed by the University of Torino,"}
              </span>{" "}
              <a
                href="https://www.unito.it/ateneo/organizzazione/amministrazione/direzioni-dellamministrazione/ricerca-terza-missione"
                className="text-secondary hover:underline"
              >
                Direzione Ricerca e Terza Missione
              </a>
              <span>
                {dictionary.language === "IT"
                  ? " e verificate dalla "
                  : " and certified by the "}
              </span>
              <a
                href="https://rol.fondazionecrt.it/"
                className="text-secondary hover:underline"
              >
                Fondazione CRT
              </a>
              <span>
                {dictionary.language === "IT"
                  ? ". Per la tracciabilità fare riferimento alla sigla ROL 2021.0511 (MESV_CRT_21_01). Il beneficiario, per le procedure di accettazione, utilizzo e pubblica fruizione del contributo erogato, segue la regolamentazione CRT 2017 art. 6. Per l’allocazione dei fondi, si fa riferimento alla stessa regolamentazione, art. 7. I giustificativi per le spese eleggibili sono specificati nel Report Manual. Il monitoraggio è regolamentato dall’art. 7.4."
                  : " Funds can be tracked by the tags ROL 2021.0511 (MESV_CRT_21_01). As for the procedures for acceptance, use and public communication of the contribution, the beneficiary will comply with the provisions of section 6 of the 2017 CRT Regulation. As for funds allocation, the beneficiary will comply with the provisions from section 7 of the 2017 CRT Regulation. The eligible justifications, based on the costs eligible for contribution under this call, are specified in the Report Manual together with the technical procedures of presentation of the report. As for monitoring of the financed activities, reference is made to what provided for in section 7.4 of the CRT 2017 Regulation."}
              </span>
            </span>

            <span className={pClass + " mb-3"}>
              <span>
                {dictionary.language === "IT"
                  ? "Oltrevita/Afterlife è cofinanziato da un "
                  : "Oltrevita/Afterlife is co-financed by a "}
              </span>
              <a
                href="https://www.fondazionecrt.it/attivit%C3%A0/arte-e-cultura/"
                className="text-secondary hover:underline"
              >
                {dictionary.language === "IT" ? "programma" : "program"}
              </a>
              <span>
                {dictionary.language === "IT"
                  ? " della Fondazione CRT e da "
                  : " held by the Fondazione CRT and "}
              </span>
              <a
                href="https://www.dipstudistorici.unito.it/do/home.pl/View?doc=finanziamenti.html"
                className="text-secondary hover:underline"
              >
                {dictionary.language === "IT"
                  ? "fondi di ricerca"
                  : "research funds"}
              </a>
              <span>
                {dictionary.language === "IT"
                  ? " dell’Università di Torino. Il progetto è stato selezionato su base comparativa grazie ad un bando pubblicato nel 2021."
                  : " granted by the University of Torino. The project was selected in a call opened in 2021. "}
              </span>
            </span>

            <a
              href={pdfFile}
              download="Bando ordinarie 2021"
              target="_blank"
              rel="noreferrer"
            >
              <button className="flex h-[5vh] w-fit items-center gap-3 text-silver hover:text-secondary mb-3">
                <FontAwesomeIcon
                  icon={faDownload}
                  size="xl"
                  className="cursor-pointer"
                />
                <span className="text-lg">
                  {dictionary.language === "IT"
                    ? "Scarica documento"
                    : "Download file"}
                </span>
              </button>
            </a>
          </div>
        </Accordion>
      </div>
    </div>
  );

  const teamSection = (
    <div className="flex flex-col w-full items-center px-5">
      <div className="w-full lg:w-[60%] flex items-center gap-3">
        <h1 className="text-silver text-xl font-semibold min-w-[20%] lg:min-w-[10%]">
          Team
        </h1>
        <hr className="w-full text-primary-light" />
      </div>

      <div className="w-full lg:w-[75%] my-10">
        <Carousel noOfCards={3}>
          {dictionary?.projectPage?.team.map((member) => (
            <TeamMember
              image={member.image ? member.image : userPlaceholder}
              myKey={member.fullName}
              fullName={member.fullName}
              role={member.role}
              foundation={member.foundation}
              linkUrl={member.link}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );

  const relatedProjectSection = (
    <div className="flex flex-col w-full h-auto px-5 bg-background-light items-center py-10">
      <div className="flex flex-col w-full lg:w-[60%] sm:w-[80%]">
        <div className="flex items-center">
          <h1 className="text-primary text-xl font-semibold min-w-[50%] sm:min-w-[10%]">
            {dictionary?.projectPage?.progettiCorrelatiTitle}
          </h1>
          <hr className="w-full text-primary-light" />
        </div>
      </div>
      <div className="w-full h-auto flex justify-center gap-[5vh] sm:gap-[10vh] pt-10">
        <a
          href="https://arcoa.cnr.it"
          alt="ArCOA"
          className="w-[25vh] h-auto flex flex-col items-center justify-center"
          target="_blank"
          rel="noreferrer"
          key="acroa"
        >
          <img src={Logo1} alt="Logo ArCOA" className="hover:opacity-75" />
        </a>
        <a
          href="https://www.dipstudistorici.unito.it/do/progetti.pl/Show?_id=kjno"
          alt="iCommunity"
          className="w-[25vh] h-auto flex flex-col items-center justify-center"
          target="_blank"
          rel="noreferrer"
          key="iCommunity"
        >
          <img src={Logo2} alt="Logo iCommunity" className="hover:opacity-75" />
        </a>
        <a
          href="https://invisiblecollections.com/"
          alt="invisibleCollections"
          className="w-[25vh] h-auto flex flex-col items-center justify-center"
          target="_blank"
          rel="noreferrer"
          key="invisibleCollections"
        >
          <img src={Logo3} alt="LOGO INVCOLL" className="hover:opacity-75" />
        </a>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={dictionary?.projectPage?.breadcrumbs} />
      <div className="flex flex-col w-full items-center">
        <Pagetitle
          title={dictionary.projectPage?.title}
          description={
            dictionary?.title +
            " " +
            dictionary?.homePage?.descrizioneProgetto +
            " " +
            dictionary?.homePage?.findsSection?.description
          }
        />
        {accordionsSection}
        {teamSection}
        {relatedProjectSection}
      </div>
    </>
  );
};

export default Project;

// Api

import { unitaApi, risorsaApi } from "../../api";

const initialState = {
  units: [],
  unit: null,
  unitResources: [],
  breadcrumbsOpera: [
    { pageName: "Home", path: "/", active: false },
    { pageName: "Le Opere", path: "/Reperti/Opere", active: false },
    {
      pageName: "Oltrevita",
      path: "/Reperti/Opere/Oltrevita/",
      active: false,
    },
  ],
  totalUnits: null,
};

const unitsStore = (set, get) => ({
  ...initialState,

  getUnits: async (filters, numberOfPage, itemsPerPage) => {
    const res = await unitaApi.getUnits(filters, numberOfPage, itemsPerPage);
    if (res && res.status === 200) {
      set({
        units: res.data ? res.data?.items : [],
        totalUnits: res.data ? res.data.totalItems : null,
      });
      return { value: true };
    } else return false;
  },

  getUnit: async (id) => {
    const res = await unitaApi.getUnit(id);

    if (res && res.status === 200) {
      set({
        unit: res.data ? res.data?.unita : null,
        unitResources: res.data ? res.data?.risorse : null,
        breadcrumbsOpera:
          (res.data?.unita?.nomeIt || res.data?.unita?.nomeEn) &&
          get().dictionary?.oltrevitaPageUnit?.breadcrumbs.map((x) =>
            x.pageName === "Oltrevita" || x.pageName === "Afterlife"
              ? {
                  pageName:
                    get().activeLang.value === "IT"
                      ? "Oltrevita " + res.data?.unita?.nomeIt
                      : "Afterlife " + res.data?.unita?.nomeEn,
                  path: `Reperti/Opere/Oltrevita/${res.data?.unita.ID}`,
                  active: true,
                }
              : x
          ),
      });

      if (res.data?.risorse && res.data?.risorse.length > 0) {
        let risorseToDisplay = [];

        for (const risorsa of res.data?.risorse) {
          switch (risorsa.tipologia) {
            //Gestione tipologie di media risorsa
            case "fumetto":
              let fumettoRes =
                risorsa.tipologia === "fumetto" &&
                (await risorsaApi.getResource(risorsa.ID));

              // Aggiungo la risorsa con la lista 'fumetto' contenente le pagine del fumetto
              risorseToDisplay.push(
                fumettoRes.data
                  ? { ...risorsa, fumetto: fumettoRes.data }
                  : risorsa
              );

              break;
            case "galleria":
              let galleriaRes = await risorsaApi.getResource(risorsa.ID);

              // Aggiungo la risorsa con la lista 'galleria' contenente le pagine del fumetto
              risorseToDisplay.push(
                galleriaRes.data
                  ? { ...risorsa, galleria: galleriaRes.data }
                  : risorsa
              );

              break;
            case "documento":
              risorseToDisplay.push({ ...risorsa });
              break;
            case "audio":
              risorseToDisplay.push({ ...risorsa });
              break;
            case "video":
              risorseToDisplay.push({ ...risorsa });
              break;
            default:
              console.log("Tipologia non riconosciuta");
          }
        }

        set({
          unitResources: risorseToDisplay,
        });
      }
    }
  },

  createUnit: async (data) => {
    const res = await unitaApi.createUnit(data);
    if (res && res.status === 200) {
      return { value: true };
    } else return false;
  },
});

export default unitsStore;

import React, { useState, useRef } from "react";
import HTMLFlipBook from "react-pageflip";

import { useStore } from "../../store/stores/store";

// COMPONENTS
import Accordion from "../Accordion.component";
import Button from "../Button.component";
import Zoom from "react-img-hover-zoom";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

const Pageflip = ({ pages = [], mobile = false, risorsa = {} }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const book = useRef();
  const [width, setWidth] = useState(mobile ? 350 : 375);
  const [height, setHeight] = useState(mobile ? 495 : 530);

  const dictionary = useStore((x) => x.dictionary);
  const activeLang = useStore((x) => x.activeLang.value);

  const totalPages = pages.length;

  const prevPage = () => {
    book.current.pageFlip().flipPrev();
    setCurrentPage(mobile ? currentPage - 1 : currentPage - 2);
  };

  const nextPage = () => {
    book.current.pageFlip().flipNext();
    setCurrentPage(mobile ? currentPage + 1 : currentPage + 2);
  };

  const handlePageFlip = (action) => {
    action === "prev"
      ? prevPage()
      : action === "next"
      ? nextPage()
      : book.current.pageFlip().getCurrentPageIndex();
  };

  return (
    <>
      <div className="relative flex flex-col items-center justify-center overflow-hidden">
        {pages && pages.length > 0 && (
          <HTMLFlipBook
            width={width}
            height={height}
            ref={book}
            useMouseEvents={false}
            mobileScrollSupport={false}
            showCover
          >
            {pages.map((page, i) => {
              return (
                <div className="cursor-zoom-in" key={"page" + i}>
                  <Zoom
                    // src={process.env.REACT_APP_FUMETTO_IMAGES_URL + page?.url}
                    img={process.env.REACT_APP_FUMETTO_IMAGES_URL + page?.url}
                    width={width}
                    height={height}
                    zoomScale={2}
                    style={{ width: "100%", height: "100%" }}
                    alt={page?.nome}
                  />
                </div>
              );
              {/* mobile ? (
                <div key={"page" + i}>
                  <img
                    src={process.env.REACT_APP_FUMETTO_IMAGES_URL + page?.url}
                    style={{ width: "100%", height: "100%" }}
                    alt={page?.nome}
                  />
                </div>
              ) :  */}
              
            })}
          </HTMLFlipBook>
        )}

        <div className="w-full flex flex-row items-center justify-between sm:justify-center sm:gap-10 mt-3 sm:mt-5 p-3">

          <div
            className={`flex flex-1 items-center justify-start sm:justify-end`}
          >
            {currentPage > 0 && (
              <Button 
                variant={2}
                classes="rounded-sm"
                onClick={() => handlePageFlip("prev")}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span className="font-semibold text-lg ml-1 ">Precedente</span>
              </Button>
            )}
          </div>

          <div
            className={`flex flex-1 items-center justify-end sm:justify-start `}
          >
            {currentPage <= totalPages - 2 && (
              <Button 
                variant={2}
                classes="rounded-sm"
                onClick={() => handlePageFlip("next")}
              >
                <span className="font-semibold text-lg mr-1 ">Successiva</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-full lg:w-[50%] sm:w-[80%]">
          <Accordion
            title={dictionary?.labels?.ulterioriInfo}
            bgColor="primary"
            textColor="silver"
            visibleHr
          >
            <div className="flex flex-col w-full p-3 sm:p-10 text-silver">
              <span className="mt-2 text-lg font-semibold text-secondary">
                {activeLang === "IT" ? risorsa.titoloIt : risorsa.titoloEn}
              </span>

              <span className="mt-2 text-sm font-semibold">
                {risorsa.autore}
              </span>

              <span className="mt-5">
                {activeLang === "IT"
                  ? risorsa.descrizioneIt
                  : risorsa.descrizioneEn}
              </span>

              {risorsa?.url && (
              <a
                href={process.env.REACT_APP_RESOURCES_URL + risorsa.url}
                download="Bando ordinarie 2021"
                target="_blank"
                rel="noreferrer"
                className="pt-3 sm:pt-0"
              >
                <button className="flex h-[5vh] w-fit items-center gap-3 text-silver hover:text-secondary">
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="xl"
                    className="cursor-pointer"
                  />
                  <span className="text-lg">
                    {dictionary.language === "IT"
                      ? "Scarica documento"
                      : "Download file"}
                  </span>
                </button>
              </a>
            )}
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Pageflip;

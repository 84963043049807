import React from "react";

const MuseoCard = ({
  onClick = () => {},
  disabled = false,
  variant,
  classes,
  children,
  title,
  subtitle,
  label,
  link,
  image,
  ...props
}) => {

  return (
      <button
        className="h-[400px] w-full lg:w-[350px] lg:h-[350px] md:h-[225px] md:w-[225px] flex flex-col bg-primary shadow-xl sm:hover:opacity-60 cursor-pointer rounded-sm"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: `100% 100%`,
          backgroundRepeat: `no-repeat`,
        }}
        onClick={() => window.open(link)}
        aria-label={label}
      />
  );
};

export default MuseoCard;
